import React, { useState } from 'react';
import './ConfirmReservation.modul.css';
import { useParams } from 'react-router-dom';
import useGetData from '../../APIs/useGetData';
import { API_URL } from '../../../config';
import usePostData from '../../APIs/usePostData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for the toast notifications
import { useLanguage } from '../../Contexts/LanguageContext';

export default function ConfirmReservation() {
  const { id } = useParams();
  const { getData, getLoading } = useGetData();
  const { postData, loading } = usePostData();
  const [responseMessage, setResponseMessage] = useState(null);
  const [errs, setErrs] = useState(null);
  const { language } = useLanguage();
  // State to control the visibility of the input field and store the input value
  const [showInput, setShowInput] = useState(false);

  let validationSchema = yup.object({
    update_reservation_notes: yup
      .string()
      .max(25, "Max is 25 characters")
      .min(3, "Min is 3 characters")
      .required("Field required"),
  });

  let formik = useFormik({
    initialValues: {
      update_reservation_notes: '',
    },
    validationSchema,
    onSubmit: submitRegister,
  });

  // Accept method
  const acceptReserve = async () => {
    try {
      const res = await getData(`${API_URL}accept/${id}?lang=${language}`);
      setResponseMessage(res); // Save the response
      toast.success(res.message || 'Reservation accepted successfully!'); // Show success toast
      
    } catch (err) {
      setErrs(err);
      toast.error(err || 'Failed to accept reservation');
    }
  };

  // Cancel method
  const cancelReserve = async () => {
    try {
      const res = await postData(`${API_URL}cancel/${id}`);
      setResponseMessage(res); // Save the response
      toast.success(res.message || 'Reservation canceled successfully!'); // Show success toast
    } catch (err) {
      setErrs(err);
      toast.error(err.response?.data?.message || 'Failed to cancel reservation');
    }
  };

  // Submit update reservation => After validation, perform the API call
  async function submitRegister(values) {
    try {
      const response = await postData(`${API_URL}update/${id}`, values);
      setResponseMessage(response); // Save the response
      toast.success(response.message || "Reservation updated successfully!");
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to update, try again later");
    }
  }

  return (
    <section>
      <div className="text-center p-5 my-5">
        <div className="card p-4 m-auto shadow">
          <h1 className="cookieHeading">
            {language === 'ar' ? 'تأكيد الحجز' : 'Confirm Reservation'}
          </h1>

          <div className="buttonContainer">
            {getLoading ? (
              <button className="acceptButton" onClick={acceptReserve}>
                <i className="fas fa-spinner fa-spin"></i>
              </button>
            ) : (
              <button className="acceptButton" onClick={acceptReserve}>
                {language === 'ar' ? 'تأكيد' : 'Confirm'}
              </button>
            )}

            {/* Toggle the visibility of the input field */}
            <button
              className="acceptButton"
              onClick={() => setShowInput(!showInput)}
            >
              {language === 'ar' ? 'تعديل' : 'Update'}
            </button>

            {/* CANCEL BUTTON */}
            <button className="declineButton" onClick={cancelReserve}>
              {language === 'ar' ? 'الغاء' : 'Cancel'}
            </button>
          </div>

          {/* Show the input field if showInput is true */}
          {showInput && (
            <div className="mt-3">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="my-2">
                    <label className="text-dark" htmlFor="update_reservation_notes">
                      {language === 'ar' ? 'سبب التعديل' : 'Reason for Update'}
                    </label>
                    <input
                      type="text"
                      id="update_reservation_notes"
                      name="update_reservation_notes"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.update_reservation_notes}
                      className={
                        formik.errors.update_reservation_notes && formik.touched.update_reservation_notes
                          ? 'input-error w-100'
                          : 'w-100'
                      }
                    />
                    {formik.errors.update_reservation_notes && formik.touched.update_reservation_notes && (
                      <div className="text-danger">{formik.errors.update_reservation_notes}</div>
                    )}
                  </div>

                  {loading ? (
                    <button disabled className="mt-2 btn btn-primary">
                      <i className="fas fa-spinner fa-spin w-100"></i>
                    </button>
                  ) : (
                    <button className="mt-2 btn btn-primary" type="submit">
                      {language === 'ar' ? 'ارسال' : 'Submit'}
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
