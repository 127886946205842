import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Bootstrap 5 JavaScript
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Offline } from "react-detect-offline";

import { UserProvider } from "./Component/Contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
      <div className="offline-div">
        <Offline className="offline ">You are offline <i className="fas fa-wifi"></i> </Offline>
      </div>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();
