import { Outlet } from "react-router-dom";
import NavBar from '../NavBar/NavBar'
import TopNav from '../TopNav/TopNav';
import Footer from "../Footer/Footer";
import Statistic from "../Statistic/Statistic";
import ScrollToTopButton from "../ScrollToTop/ScrollToTop";
import { useLanguage } from "../Contexts/LanguageContext";

export default function Layout() {
const {language}=useLanguage()  
  return (
    <div className={`${language=='ar'?'rtl':""} overflow-x-hidden`}>
      <TopNav />
      <NavBar />
      <Outlet />
      <Statistic />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}
