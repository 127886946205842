import React, { useEffect, useState } from 'react';
import useGetData from '../../APIs/useGetData';
import { API_URL } from '../../../config';
import { useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import './ServiceDetails.modul.css'; 
import { useLanguage } from '../../Contexts/LanguageContext';

export default function ServiceDetails() {
  const { id } = useParams();
  const { getData, getLoading } = useGetData();
  const [response, setResponse] = useState(null);
  const [errs, setErrs] = useState(null);
  const { language } = useLanguage()
  useEffect(() => {
    getData(`${API_URL}single-services/${id}?lang=${language}`)
      .then(res => setResponse(res))
      .catch(err => setErrs({ err }));
  }, [id]);

  if (getLoading) {
    return <Loader />;
  }

  if (!response) {
    return <p>No data available.</p>;
  }
  if (errs) return <div className="alert alert-danger text-center">{errs.message || "Some thing wrong happened"}</div>

  return (
    <section className="service-details">
      <div className="contain text-center">
        <div className="service-details-content">
          <h2 className="service-title">{response.name}</h2>
          <p className="service-description">{response.content}</p>
        </div>
      </div>
    </section>
  );
}
