import React from 'react'
import Button from '../../Button/Button'
import Slider from 'react-slick'
import doctor1 from '../../../assets/images/Doctor01.png'
import doctor2 from '../../../assets/images/Doctor02.png'
import doctor3 from '../../../assets/images/Doctor03.png'
import doctor4 from '../../../assets/images/Doctor04.png'
import doctor5 from '../../../assets/images/Doctor05.png'
import { useLanguage } from '../../Contexts/LanguageContext';
import BackText from '../../../BackText/BackText';
export default function HomeTeamSlider() {
    const settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024, // You can adjust this breakpoint
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768, // Adjust for tablets
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 576, // Adjust for mobile
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      };
   const {language}= useLanguage()
  return (
    <section className='my-md-5 my-3 pb-md-5'>
    {/* START HEAD */}
    <div className="team-header my-5 text-center">
    <div className="position-relative">
      <h1>{language=='ar'?"خبراء في مجال طب الاسنان":"Dental experts"}</h1>
            <BackText textAr={'فريقنا'} textEn={'Our Team'}/>

          </div>
      <h3>{language=="ar"?"الفريق الطبي بعيادات عاجي لطب الاسنان":"Medical team at Ajay Dental Clinics"} </h3>
          <p>
          {language=="ar"?" افضل عيادات لطب الأسنان في المملكه السعوديه.نحن نقدم جميع العلاجات عن طريق الفم بما في ذلك زراعة الأسنان":
          "The best dental clinics in the Kingdom of Saudi Arabia. We provide all oral treatments including dental implants."}
           
          </p>
      <p>Gerek 3D , Panoramic X-Ray & Galileos CT Machine </p>
    </div>
    <div className="row g-5 home-slider home-videos-sec ">
      <Slider {...settings1} className=''>
       
        
        <div className="slide">
          <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
            <div className='doctorCard'>
              <img src={doctor1} className=" rounded-circle m-auto doctor-img p-1"  alt="Doctor" />
              <h3>Mostafa Elsamanody</h3>
              <h4>Doctor</h4>
              <div className="social-ic">
                <ul className="d-flex justify-content-center  list-style">
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-facebook'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.instgram.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-twitter'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-telegram'></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
   
        <div className="slide">
          <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
            <div className='doctorCard'>
              <img src={doctor2} className=" rounded-circle m-auto doctor-img p-1"  alt="Doctor" />
              <h3>Mohammed Fayyad</h3>
              <h4>Doctor</h4>
              <div className="social-ic">
                <ul className="d-flex justify-content-center  list-style">
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-facebook'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.instgram.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-twitter'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-telegram'></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
   
        <div className="slide">
          <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
            <div className='doctorCard'>
              <img src={doctor3} className=" rounded-circle m-auto doctor-img p-1"  alt="Doctor" />
              <h3>Samira Elsayyed</h3>
              <h4>Doctor</h4>
              <div className="social-ic">
                <ul className="d-flex justify-content-center  list-style">
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-facebook'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.instgram.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-twitter'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-telegram'></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
   
        <div className="slide">
          <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
            <div className='doctorCard'>
              <img src={doctor4} className=" rounded-circle m-auto doctor-img p-1"  alt="Doctor" />
              <h3>Amit </h3>
              <h4>Doctor</h4>
              <div className="social-ic">
                <ul className="d-flex justify-content-center  list-style">
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-facebook'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.instgram.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-twitter'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-telegram'></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
   
        <div className="slide">
          <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
            <div className='doctorCard'>
              <img src={doctor5} className=" rounded-circle m-auto doctor-img p-1"  alt="Doctor" />
              <h3>Mohammed Hossam</h3>
              <h4>Doctor</h4>
              <div className="social-ic">
                <ul className="d-flex justify-content-center  list-style">
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-facebook'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.instgram.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-instagram'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-twitter'></i>
                    </a>
                  </li>
                  <li className="mx-2 text-dark">
                    <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                      <i className='fab fa-telegram'></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
   
      </Slider>

    </div>
  </section>
  )
}
