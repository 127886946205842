import React, { useEffect, useState } from 'react';
import useGetData from '../../APIs/useGetData';
import Loader from '../../Loader/Loader';
import { useParams } from 'react-router-dom';
import './PostDetails.modul.css'
import { API_URL } from '../../../config';
import { useLanguage } from '../../Contexts/LanguageContext';
export default function PostDetails() {
    const [articleData, setArticleData] = useState(null); // Initial state set to null
    const { getData, getLoading, errors } = useGetData();
    const { postId } = useParams()
    const {language} =useLanguage()
    useEffect(() => {
        getData(`${API_URL}single-post/${postId}?lang=${language}`) // basem ar lang english !! 
            .then(data => setArticleData(data)) // Update to set only the data object
            .catch(err => console.error(err));
    }, [postId]);

    if (getLoading) {
        return <Loader />;
    }

    if (errors) {
        return <div>Error: {errors.message}</div>;
    }

    if (!articleData) {
        return <div>No data found</div>; // Handle the case when no data is available
    }

    return (
        <section>
            <div className="contain post-details py-5">
                <h1>{articleData.title}</h1>
                <h2>{articleData.sub_title}</h2>
                <div className="row p-5 d-flex justify-content-center align-items-center">
                    <div className="col-md-4 shadow">
                        <img
                            src={`${articleData.cover_photo_path}`}
                            alt={articleData.photo_alt_text}
                            className="w-100 h-100   object-fit-contain"
                        />
                    </div>
                    <div className="col-md-6">
                        <div dangerouslySetInnerHTML={{ __html: articleData.body }}></div>

                        <p>تم النشر في : {new Date(articleData.published_at).toLocaleDateString()}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
