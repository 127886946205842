import React, { useState } from 'react';
import ad from '../../assets/images/dentist.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../APIs/usePostData';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';

export default function ResetPassword() {
  const [errs, setErrs] = useState([]);
  const { postData, loading } = usePostData();
  const navigate = useNavigate();
const{language}=useLanguage()
  async function submitRegister(values) {
    try {
      const response = await postData(`${API_URL}reset-password`, values);
      
      if (response && response.data && !(typeof response.data === 'object')) {
        toast.success(`${language=="ar"?"تم تغيير الباسوورد بنجاح":"Password changed successfully"}`);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } catch (err) {
      setErrs(err.response?.data?.errors);
      toast.error('An error occurred during password reset.');
    }
  }

  const validationSchema = yup.object({
    code: yup.number('Must be a number').required("Field required"),
    mobile: yup.number('Must be a number').required("Field required"),
    password: yup.string().required("Field required"),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      mobile: '',
      password: '',
    },
    validationSchema,
    onSubmit: submitRegister
  });

  return (
    <section className='signup my-5 p-3'>
      <div className="contain">
        <div className="row">
          <div className="col-md-6">
            <h2 className='mb-md-4'> {language=="ar"?"تغيير الرقم السري":"Reset Password"}</h2>
            <span className='alert alert-success my-5 p-2'>{language=="ar"?"لقد تم ارسال الكود الي الواتس برجاء المراجعه":"Code sent to your whats app"}</span>

            <form onSubmit={formik.handleSubmit} className='mt-md-5'>
            {errs ? Object.entries(errs).map(([field, messages], index) => (
                <div key={index} className="error-item">
                  <ul>
                    {messages.map((message, i) => (
                      <li key={i} className="text-danger">{message}</li>
                    ))}
                  </ul>
                </div>
              )) : ""}

              <div className="row">
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="code">{language=="ar"?"الكود المرسل ":"Code"}</label>
                  <input
                    type="text"
                    id='code'
                    name='code'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.code}
                    className={formik.errors.code && formik.touched.code ? 'input-error' : ''}
                    
                  />
                  {formik.errors.code && formik.touched.code && (
                    <div className="text-danger">{formik.errors.code}</div>
                  )}
                </div>

                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="mobile">{language=="ar"?"رقم الهاتف":"phone number"}</label>
                  <input
                    type="text"
                    id='mobile'
                    name='mobile'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    className={formik.errors.mobile && formik.touched.mobile ? 'input-error' : ''}
                  />
                  {formik.errors.mobile && formik.touched.mobile && (
                    <div className="text-danger">{formik.errors.mobile}</div>
                  )}
                </div>

                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="password">{language=="ar"?"الباسورد":"New password"}</label>
                  <input
                    type="password"
                    id='password'
                    name='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={formik.errors.password && formik.touched.password ? 'input-error' : ''}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                </div>
              </div>

              <div className="my-3 text-center">
                {loading ? (
                  <button type='button' className='btn bg-mainColor text-white d-block py-3 disabled w-25'>
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button type='submit' className='btn bg-mainColor text-white d-block py-3 w-25' disabled={!(formik.dirty && formik.isValid)}>
                    {language=="ar"?"ارسال":"Send"}
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="col-md-5 me-auto">
            <img src={ad} className='w-100 h-100' alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
