import React, { useEffect, useState } from 'react';
import opinierImg from '../../../assets/images/whyusimg.png';
import Slider from 'react-slick';
import { useLanguage } from '../../Contexts/LanguageContext';
import useGetData from '../../APIs/useGetData';
import { API_URL } from '../../../config';
import Loader from '../../Loader/Loader';

export default function CustomersOpinionSlider() {
  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const [data, setData] = useState([]); // Initialize state with an empty array
  const [error, setError] = useState(null); // State for error handling
  const { language } = useLanguage();
  const { getData, getLoading } = useGetData();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData(`${API_URL}sayings`);
        setData(res);
      } catch (err) {
        setError(err); // Capture error message
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div className="error-message  text-center"> </div>;
  }
  if (!data) {
    return <div className="error-message">Error: { }</div>;
  }

  return (
    <section className='contain my-md-5 my-3 py-md-5'>
      {/* START HEAD */}
      <div className="home-customer-head d-flex justify-content-between align-items-center">
        <div className="text-75 my-5 ">
          <h1 className='text-mainColor'>
            {language === "ar" ? "آراء عملاؤنا في خدماتنا " : "Medical team at Ajay Dental Clinics"}
          </h1>
          <p>
            {language === "ar"
              ? "أفضل عيادات لطب الأسنان في المملكة السعودية. نحن نقدم جميع العلاجات عن طريق الفم بما في ذلك زراعة الأسنان ، CEREC 3D ، Panoramic X-Ray & Galileos CT Machine ، قشرة الأسنان & Lumineers ،"
              : "The best dental clinics in the Kingdom of Saudi Arabia. We provide all oral treatments including dental implants."}
          </p>
        </div>
        <div className="customers-testmoials">
          <h3 className='text-mainColor borderBottom'>
            {language === "ar" ? "ماذا يقول عملاؤنا" : "What our customers say"}
          </h3>
          <p>
            {language === "ar"
              ? "آراء مرضانا الذين خبروا الخدمات الصحية في عيادتنا"
              : "Opinions of our patients who experienced the health services in our clinic"}
          </p>
        </div>
      </div>

      <div className="row g-5 home-slider home-videos-sec customers-opinion-slider">
        <Slider {...settings3}>
          {getLoading ? <Loader /> : 
          data.map((item, ind) => (

          <div className="slide" key={ind}>
            <div className="opinier d-flex align-items-center flex-column bg-white p-3 rounded-4">
              <div className="row w-100 d-flex align-items-center">
                <div className="col-9 my-3">
                  <div className='text-start'>
                    <h6 className='fw-bold'>{item.user_name || "محمد محي الدين"}</h6>
                    {/* <h6>مصمم واجهات المستخدم</h6> */}
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-end">
                  <div className="home-opinier-img">
                    <img src={item.profile_photo_path|| opinierImg} className='rounded-circle' alt="" />
                  </div>
                </div>
              </div>
              <div className="starts d-flex justify-content-end w-100 my-3">
                {Array.from({ length: item.rating }, (_, index) => (
                  <i key={index} className="fas fa-star mx-2"></i> // Render stars based on rating
                ))}
              </div>
              <h4 className='mb-3'>{item.client_saying || "حقا ساعدني من أصيب بتأثير خفيف وكان مطلوبا منه أن يعزل نفسه بالمراقبة على الانترنت"}</h4>
            </div>
          </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
