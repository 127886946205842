import React from 'react'
import './NotFound.modul.css'
export default function NotFound() {
    return (
        <div class="section text-center">
            <h1 class="error">404</h1>
            <div class="page">Ooops!!! The page you are looking for is not found</div>
            <a class="back-home" href="/">Back to home</a>
        </div>
    )
}
