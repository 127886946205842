import React from "react";
import imageInstead from '../../assets/images/imageInstead.png';
import './ArticlePost.modul.css';
import { Link } from "react-router-dom";
import { useLanguage } from "../Contexts/LanguageContext";

export default function ArticlePost({ data }) {
  const { language } = useLanguage()
  if (!data) return "No posts";
  return (
    <div className="article-post">
      <img
        src={data.cover_photo_path || imageInstead}
        className="w-100"
        alt={data.photo_alt_text || 'Article Image'}
      />
      <div className=" pe-0 my-3  p-3">
        {data.categories ?
          data.categories.map((category, ind) => <Link key={ind} to={`/categoryposts/${category.id}`}>
            <h5 className="article-headline text-mainColor">
              {category.name}
            </h5>
          </Link>)
          : ""}
        <h4>{data.title}</h4>
        <p>
          {data.body.split(' ').slice(0, 30).join(' ')}...
          <span> <Link to={`/postdetails/${data.id}`}>{language == "ar" ? "عرض المزيد" : "Show more"}</Link> </span>
        </p>
      </div>
    </div>
  );
}
