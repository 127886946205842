import React, { useContext, useEffect, useState } from 'react';
import ad from '../../../assets/images/dentist.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../../APIs/usePostData';
import useGetData from '../../APIs/useGetData';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Profile.modul.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { UserContext } from '../../Contexts/UserContext';
import { API_URL } from '../../../config';
import useGetDataAuth from '../../APIs/useGetDataAuth';
import Loader from '../../Loader/Loader';
import { useLanguage } from '../../Contexts/LanguageContext';
import placeholderImage from '../../../assets/images/image42.png'; // Add a placeholder image for profile

export default function SignUp() {
    const [cities, setCities] = useState([]);
    const [userInfos, setUserInfos] = useState(null);
    const [errs, setErrs] = useState([]);
    const { postData, loading } = usePostData();
    const { getData, getLoading } = useGetData();
    const { getDataAuth } = useGetDataAuth();
    const { token } = useContext(UserContext);
    const storedToken = token || localStorage.getItem('token');
    const { language } = useLanguage();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const citiesData = await getData(`${API_URL}cities?lang=${language}`);
                setCities(citiesData);

                const profileData = await getDataAuth(`${API_URL}profile`, storedToken);
                setUserInfos(profileData);
            } catch (err) {
                setErrs(prev => [...prev, err.message]);
            }
        };
        fetchData();
    }, []);
    // Define validation schema using Yup
    const validationSchema = yup.object({
        first_name: yup.string().required(`${language === 'ar' ? 'الاسم الأول مطلوب' : 'First name is required'}`),
        last_name: yup.string().required(`${language === 'ar' ? 'الاسم الأخير مطلوب' : 'Last name is required'}`),
        age: yup.number().required(`${language === 'ar' ? 'العمر مطلوب' : 'Age is required'}`).positive().integer(),
        city_id: yup.string().required(`${language === 'ar' ? 'المدينة مطلوبة' : 'City is required'}`),
        marital_status: yup.string().required(`${language === 'ar' ? 'الحالة الاجتماعية مطلوبة' : 'Marital status is required'}`),
        user_name: yup.string().required(`${language === 'ar' ? 'اسم المستخدم مطلوب' : 'Username is required'}`),
        email: yup.string().email(`${language === 'ar' ? 'أدخل بريدًا إلكترونيًا صحيحًا' : 'Enter a valid email'}`).required(`${language === 'ar' ? 'البريد الإلكتروني مطلوب' : 'Email is required'}`),
        mobile: yup.string().required(`${language === 'ar' ? 'رقم الهاتف مطلوب' : 'Mobile number is required'}`),
        profile_photo_path: yup.mixed(),
    });


    const formik = useFormik({
        initialValues: {
            first_name: userInfos?.first_name || '',
            last_name: userInfos?.last_name || '',
            age: userInfos?.age || '',
            city_id: userInfos?.city_id || '',
            marital_status: userInfos?.marital_status || '',
            user_name: userInfos?.user_name || '',
            email: userInfos?.email || '',
            mobile: userInfos?.mobile || '',
            profile_photo_path: userInfos?.profile_photo_path || ''
        },
        validationSchema, // Apply validation schema

        enableReinitialize: true,
        onSubmit: submitRegister,
    });

    async function submitRegister(values) {
        const formData = new FormData();
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('age', values.age);
        formData.append('city_id', values.city_id);
        formData.append('marital_status', values.marital_status);
        formData.append('user_name', values.user_name);
        formData.append('email', values.email);
        formData.append('mobile', values.mobile);
        if (values.profile_photo_path) {
            formData.append('profile_photo_path', values.profile_photo_path); // Attach file to FormData
        }

        try {
            const response = await postData(`${API_URL}update-profile`, formData, storedToken, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            if (response.message === 422) {
                setErrs(response.data);
                // console.log(response)
                toast.error('Failed to update. Please try again.');
                // setTimeout(() => navigate('/profile'), 2000);

            } else {
                // console.log(response)
                toast.success('Update success!');
                setTimeout(() => navigate('/profile'), 2000);
            }
        } catch (err) {
            // setErrs(prev => [...prev, err?.message]);
            toast.error(`${language === 'ar' ? "حدث خطأ ما" : "An error occurred"}`);
        }
    }

    if (getLoading || loading) {
        return <Loader />;
    }



    return (
        <section className='signup my-5 p-3'>
            <div className="contain">
                <div className="row">
                    <div className="col-md-6">
                        <h2>{`${language === 'ar' ? "تعديل البيانات" : "Update Profile"}`}</h2>
                        <form onSubmit={formik.handleSubmit}>

                            {/* Profile Photo */}

                            <div className="profile-image mb-3 d-flex justify-content-center align-items-center flex-column">
                                <label htmlFor="profile_photo_path">{`${language === 'ar' ? " اضغط علي الصورة الشخصية لتغييرها " : "Click on Profile Photo to change"}`}</label>

                                <img
                                    src={
                                        formik.values.profile_photo_path instanceof File
                                            ? URL.createObjectURL(formik.values.profile_photo_path)
                                            : placeholderImage
                                    }
                                    alt="Profile"
                                    className="rounded-circle mb-3 profile-image-hover"
                                    style={{ width: '130px', height: '130px', objectFit: 'cover', cursor: 'pointer' }}
                                    onClick={() => document.getElementById('profile_photo_input').click()}
                                />

                                <input
                                    type="file"
                                    id="profile_photo_input"
                                    name="profile_photo_path"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        formik.setFieldValue('profile_photo_path', event.currentTarget.files[0]);
                                    }}
                                    className={formik.errors.profile_photo_path && formik.touched.profile_photo_path ? 'input-error alert alert-danger' : ''}
                                />

                                {formik.errors.profile_photo_path && (
                                    <div className="text-danger">{formik.errors.profile_photo_path}</div>
                                )}
                            </div>
                            <div className="row ">
                                {/* FIRST NAME */}
                                <div className="col-md-6 my-2">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="first_name"> {`${language == 'ar' ? "الاسم الاول" : "First name"}`} </label>
                                    <input
                                        placeholder="مثال محمد"
                                        type="text"
                                        id='first_name'
                                        name='first_name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.first_name}
                                        className={`${formik.errors.first_name && formik.touched.first_name ? 'input-error alert alert-danger' : ''} ${language === 'en' ? 'text-end' : ''}`}
                                    />

                                    {formik.errors.first_name && formik.touched.first_name && (
                                        <div className="text-danger">{formik.errors.first_name}</div>
                                    )}
                                </div>
                                {/* LAST NAME */}
                                <div className="col-md-6 my-2">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="last_name">{`${language == 'ar' ? "الاسم الاخير" : "Last name"}`} </label>
                                    <input
                                        type="text"
                                        id='last_name'
                                        name='last_name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.last_name}
                                        className={`${formik.errors.last_name && formik.touched.last_name ? 'input-error alert alert-danger' : ''} ${language === 'en' ? 'text-end' : ''}`}
                                    />
                                    {formik.errors.last_name && formik.touched.last_name && (
                                        <div className="text-danger">{formik.errors.last_name}</div>
                                    )}
                                </div>
                                {/* USER NAME */}
                                <div className="col-md-6 my-2">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="user_name">{`${language == 'ar' ? "اسم المستخدم " : "user name"}`} </label>
                                    <input
                                        placeholder="مثال فياض"
                                        type="text"
                                        id='user_name'
                                        name='user_name'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.user_name}
                                        className={`${formik.errors.user_name && formik.touched.user_name ? 'input-error alert alert-danger' : ''} ${language === 'en' ? 'text-end' : ''}`}

                                    />
                                    {formik.errors.user_name && formik.touched.user_name && (
                                        <div className="text-danger">{formik.errors.user_name}</div>
                                    )}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="email">{`${language == 'ar' ? "الايميل" : "ُEmail"}`} </label>
                                    <input
                                        type="email"
                                        id='email'
                                        name='email'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        className={`${formik.errors.email && formik.touched.email ? 'input-error alert alert-danger' : ''} ${language === 'en' ? 'text-end' : ''}`}

                                    />
                                    {formik.errors.email && formik.touched.email && (
                                        <div className="text-danger">{formik.errors.email}</div>
                                    )}
                                </div>
                                {/* Phone */}
                                <div className="col-md-6 my-2 h-100">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="mobile">{`${language == 'ar' ? "رقم الهاتف" : "mobile phone"}`} </label>
                                    <PhoneInput
                                        international
                                        defaultCountry="SA"
                                        value={formik.values.mobile}
                                        onChange={phone => formik.setFieldValue('mobile', phone)}
                                        onBlur={formik.handleBlur}
                                        name='mobile'
                                        id='mobile'
                                        className={`${formik.errors.mobile && formik.touched.mobile ? 'form-control input-error alert alert-danger py-3' : 'form-control py-3'} ${language === 'en' ? 'text-end' : ''}`}

                                        placeholder="1234567890"
                                    />
                                    {formik.errors.mobile && formik.touched.mobile && (
                                        <div className="text-danger">{formik.errors.mobile}</div>
                                    )}
                                </div>
                                {/* AGE */}
                                <div className="col-md-6 my-2">
                                    <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="age">{`${language == 'ar' ? "العمر" : "Age"}`} </label>
                                    <input
                                        type="text"
                                        id='age'
                                        name='age'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.age}
                                        className={`${formik.errors.age && formik.touched.age ? 'input-error alert alert-danger' : ''} ${language === 'en' ? 'text-end' : ''}`}

                                    />
                                    {formik.errors.age && formik.touched.age && (
                                        <div className="text-danger">{formik.errors.age}</div>
                                    )}
                                </div>
                                {/* CITY */}
                                <div className="form-row mb-md-4 col-md-6 my-2">
                                    <div className="form-group">
                                        <label className={`text-dark ${language == 'en' ? "text-end" : ""}`} htmlFor="city_id">{`${language == 'ar' ? "المدينه" : "City"}`} </label>
                                        <select
                                         className={formik.errors.city_id && formik.touched.city_id ? 'input-error alert alert-danger form-select p-3' : 'form-select p-3'}
                                            id='city_id'

                                            name='city_id'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.city_id}>
                                            <option value='' disabled>{`${language == 'ar' ? "اختر المدينه" : "Choose city"}`} </option>
                                            {cities.map((city) => (
                                                <option key={city.id} value={city.id}>{city.name}</option>
                                            ))}
                                        </select>
                                        {formik.errors.city_id && formik.touched.city_id && (
                                            <div className="text-danger">{formik.errors.city_id}</div>
                                        )}
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-12 my-2">
                                <button type="submit" className="btn text-white bg-mainColor w-100 my-3">
                                    {loading ? <i className="fas fa-spinner fa-spin"></i> : `${language === 'ar' ? "تعديل" : "Edit"}`}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <img src={ad} alt="signup" className="img-fluid" />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
}
