import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import usePostData from "../APIs/usePostData";
import { API_URL } from "../../config";
import { ToastContainer, toast } from 'react-toastify';
import useGetData from '../APIs/useGetData'
import { useLanguage } from "../Contexts/LanguageContext";

export default function ContactForm() {
  const { postData, loading } = usePostData();
  const [services, setServices] = useState()
  const [errs, setErrs] = useState()
  const { getData, getLoading } = useGetData()
  const { language } = useLanguage()

  // Define error messages based on the language
  const minName = language == 'en' ? "Name must be at least 3 characters" : "الاسم يجب أن يكون 3 أحرف على الأقل";
  const requiredName = language == 'en' ? "Name is required" : "الاسم مطلوب";
  const invalidEmail = language == 'en' ? "Invalid email address" : "البريد الإلكتروني غير صحيح";
  const requiredEmail = language == 'en' ? "Email is required" : "البريد الإلكتروني مطلوب";
  const requiredService = language == 'en' ? "Please select a service" : "يرجى اختيار الخدمة";
  const minMessage = language == 'en' ? "Message must be at least 10 characters" : "الرسالة يجب أن تكون 10 أحرف على الأقل";
  const requiredMessage = language == 'en' ? "Message is required" : "الرسالة مطلوبة";

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      service_id: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, minName)
        .required(requiredName),
      email: Yup.string()
        .email(invalidEmail)
        .required(requiredEmail),
      service_id: Yup.string().required(requiredService),
      message: Yup.string()
        .min(10, minMessage)
        .required(requiredMessage),
    }),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const response = await postData(`${API_URL}contact_us`, values);
        toast.success(language == 'en' ? 'Message sent successfully!' : 'تم إرسال الرسالة بنجاح!');
        formik.resetForm(); // Reset the form on success
      } catch (err) {
        if (err.response && err.response.data) {
          // Display errors for each field
          // Object.entries(err.response.data.errors).forEach(([field, messages]) => {
          //   setFieldError(field, messages[0]); // Set the first error for each field
          // });
        } else {
          toast.error(language == 'en' ? 'An error occurred, please try again later.' : 'حدث خطأ أثناء الإرسال، يرجى المحاولة لاحقاً.');
        }
      } finally {
        setSubmitting(false); // Turn off the loading state
      }
    },
  });

  useEffect(() => {
    getData(`${API_URL}services?lang=${language}`)
      .then(res => setServices(res.items))
      .catch(err => setErrs(err))
  }, [language])
  if (errs) return <div className="alert text-danger text-center">{errs.message || "Some thing wrong happened"}</div>
  return (
    <div className="contact-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row mb-md-4">
          {/* NAME */}
          <div className="form-group">
            <label className={` ${language == 'en' ? "ltr" : ""}`}>{language == 'ar' ? 'الاسم' : 'Name'}</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder={` ${language == 'ar' ? " ادخل الاسم " : "Enter your name"}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={`error-message text-danger ${language == 'en' ? "ltr" : ""}`}>
                {formik.errors.name}
              </div>
            ) : null}
          </div>
          {/* EMAIL */}
          <div className="form-group">
            <label className={` ${language == 'en' ? "ltr" : ""}`}>{language == 'ar' ? 'البريد الإلكتروني' : 'Email'}</label>
            <input
              type="email"
              placeholder={` ${language == 'ar' ? " ادخل الايميل " : "Enter your email"}`}
              name="email"
              className={` ${language == 'en' ? "ltr" : ""}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={`error-message text-danger ${language == 'en' ? "ltr" : ""}`}>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
        </div>
        {/* SERVICE */}
        <div className="form-row mb-md-4">
          <div className="form-group">
            <label className={` ${language == 'en' ? "ltr" : ""}`}>{language == 'ar' ? 'الخدمه' : 'Service'}</label>
            <select
              className={`{form-select} ${language == 'en' ? "ltr" : ""}`}
              name="service_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.service_id}
            >
              <option value=""> {language == 'ar' ? ' اختر الخدمه ' : 'Choose service'}</option>
              {services?.map(service => (
                <option key={service.id} value={service.id}>{service.name}</option>
              ))}
            </select>
            {formik.touched.service_id && formik.errors.service_id ? (
              <div className={`error-message text-danger ${language == 'en' ? "ltr" : ""}`}>
                {formik.errors.service_id}
              </div>
            ) : null}
          </div>
        </div>
        {/* MESSAGES */}
        <div className="form-group mb-md-4">
          <label className={` ${language == 'en' ? "ltr" : ""}`}>{language == 'ar' ? ' الرسائل ' : 'Message'}</label>
          <textarea
            rows="5"
            name="message"
            placeholder={`${language == 'ar' ? "اكتب رسالتك .." : "Write your message.."}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={` ${language == 'en' ? "ltr" : ""}`}
            value={formik.values.message}
          ></textarea>
          {formik.touched.message && formik.errors.message ? (
            <div className={`error-message text-danger ${language == 'en' ? "ltr" : ""}`}>
              {formik.errors.message}
            </div>
          ) : null}
        </div>

        {loading ? (
          <button
            type="submit"
            className="my-md-3 py-md-3 py-2 my-2 rounded-3"
            disabled
          >
            <i className="fas fa-spinner fa-spin"></i>
          </button>
        ) : (
          <button type="submit" className="my-md-3 py-md-3 py-2 my-2 rounded-3"  >
            {language == 'ar' ? "ارسال " : "Send"}
          </button>
        )}
      </form>
      <ToastContainer />
    </div>
  )
}
