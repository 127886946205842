import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import './CategoryPosts.modul.css';
import ArticlePost from '../Article/ArticlePost';
import useGetData from '../APIs/useGetData';
import { API_URL } from '../../config';

export default function CategoryPosts() {
  const { id } = useParams();
  let [categoryPosts, setCategoryPosts] = useState([])
  let [errs, setErrs] = useState([])
  let { getData, getLoading, getError } = useGetData()
  useEffect(() => {
    getData(`${API_URL}posts_of_category/${id}}`)
      .then((response) => setCategoryPosts(response))
      .catch(err => console.error(err));
     setErrs(categoryPosts)
  }, []);

  if (getLoading) return <Loader />;
  if (getError) return <p>Error loading posts: {getError.message}</p>;
  if (errs) return <p>Error loading posts: {errs.message}</p>;

  return (<>
    {getLoading?<Loader/>:
    categoryPosts? <div className="category-posts contain p-4">
      <h2 className="text-center text-mainColor mb-4">Posts in this Category</h2>
      <div className="posts-container d-flex flex-wrap justify-content-center">
        {categoryPosts.map(post => (
          <div key={post?.id} className="post flex-item mb-4 p-3  rounded">
            <ArticlePost data={post} />
          </div>
        ))}
      </div>
    </div>:<div className='w-100 text-center p-5 m-5'>
      <h2> NO POSTS</h2>
      <Link to="/categories" className='btn p-2 bg-hover-transparent bg-mainColor text-white'>Back</Link>
      </div> 
    
    }
   
  </>
  );
}
