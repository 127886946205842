import React, { useEffect, useState } from "react";
import "./Services.modul.css";
import BeforeAfterComparison from "../BeforeAfterComparison/BeforeAfterComparison"; // Ensure the path is correct
import Slider from "react-slick";
import doctorImg from '../../assets/images/dentist.png'
import Button from "../Button/Button";
import useGetData from "../APIs/useGetData";
import { API_URL } from "../../config";
import ServicesBody from "./ServicesBody";
import ContactForm from "../Contact/ContactForm";
import { useLanguage } from "../Contexts/LanguageContext";
import BackText from "../../BackText/BackText"
const Services = () => {
  const [response, setResponse] = useState([])
  const [errs, setErrs] = useState([])
  const { language } = useLanguage()

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // You can adjust this breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, // Adjust for tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576, // Adjust for mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const { getData, getLoading } = useGetData()
  useEffect(() => {
    getData(`${API_URL}services?lang=${language}`)
      .then(res => setResponse(res.data))
      .catch(err => setErrs({ err }))
  }, [language])
  // if (errs) return <div className="alert alert-danger text-center">{errs.message || "Some thing wrong happened"}</div>

  return (
    <section className="services my-md-5 my-3">

      <div className="contain my-lg-5 my-2">
        {/* SERVICES HEAD */}
        <div className="services-head col-lg-6 text-center m-auto">
          <h1 className="my-4 position-relative">
            <BackText textAr={"خدماتنا"} textEn={"Ourservices"}/>
            <span>{language == 'ar' ? "خدماتناالطبيه" : "Our medical services"}</span> {language == 'ar' ? "متخصصة تلبي احتياجاتك" : "specialized to meet your needs"}{" "}
          </h1>
          <p>
            {language == 'ar' ? " أفضل عيادات لطب الأسنان في المملكة السعودية. نحن نقدم جميع العلاجات عن طريق الفم بما في ذلك زراعة الأسنان ، CEREC 3D ، Panoramic X-Ray & Galileos CT Machine ، قشرة الأسنان &" :
              "Best Dental Clinics in Saudi Arabia. We provide all oral treatments including Dental Implants, CEREC 3D, Panoramic X-Ray & Galileos CT Machine, Veneers &"}

          </p>
        </div>
        {/*  */}
        <ServicesBody />
      </div>


      {/*  home-bottom-slider */}
      <section className="home-videos-sec home-bottom-slider my-md-5 my-3 py-md-5   ">
        <div className="row m-0  w-100 align-items-center ">
          <div className="col-lg-4  me-auto">
            <h3 className="underline">{language == 'ar' ? "من داخل عيادات العاجي" : "From inside  Aagi clinics"}</h3>
            <h2 className="py-3">{language == 'ar' ? "خدماتنا المتميزة مصدر سعادة لكافة المرضى ." : "Our distinguished services are a source of happiness for all patients."}</h2>
            <h4 className="pb-3">
              {language == "ar" ? "نقوم بتوثيق العديد من عمليات التدريب للسماح لمرضانا برؤية أكثر وضوحاً كيف نعمل حتى لا تترددوا في تجربة خدماتنا" :
                "We document many of our training processes to allow our patients to see more clearly how we work so that you do not hesitate to try our services."}
            </h4>

          </div>
          <div className="col-lg-6   ">
            <Slider {...settings2} className='py-5 w-100'>
              <div className="slide">
                <div className="opinion d-flex flex-column justify-content-center align-items-center text-center m-auto ">
                  <div className='doctorCard  '>
                    <img src={doctorImg} className="w-100" alt="Doctor" />
                    <h3>Mohammed Hossam</h3>
                    <h4>Doctor</h4>
                    <div className="social-ic">
                      <ul className="d-flex justify-content-center m-0 list-style">
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>

                      </ul>
                    </div>
                    <Button text={'احجز موعد'} to={'/'} />
                  </div>

                </div>
              </div>
              <div className="slide">
                <div className="opinion d-flex flex-column justify-content-center align-items-center text-center  m-auto ">
                  <div className='doctorCard '>
                    <img src={doctorImg} className="w-100" alt="Doctor" />
                    <h3>Mohammed Hossam</h3>
                    <h4>Doctor</h4>
                    <div className="social-ic">
                      <ul className="d-flex justify-content-center m-0 list-style">
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>

                      </ul>
                    </div>
                    <Button text={'احجز موعد'} to={'/'} />
                  </div>

                </div>
              </div>
              <div className="slide">
                <div className="opinion d-flex flex-column justify-content-center align-items-center text-center  m-auto ">
                  <div className='doctorCard '>
                    <img src={doctorImg} className="w-100" alt="Doctor" />
                    <h3>Mohammed Hossam</h3>
                    <h4>Doctor</h4>
                    <div className="social-ic">
                      <ul className="d-flex justify-content-center m-0 list-style">
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>
                        <li className="mx-2">
                          <a href='https://www.facebook.com' target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-facebook'></i>
                          </a>
                        </li>

                      </ul>
                    </div>
                    <Button text={'احجز موعد'} to={'/'} />
                  </div>

                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* SMAILE SECTION */}
      <section className="smile-section text-center m-auto">
        <marquee
          direction="left"
          loop="infinite"
          behavior="scroll"
          className="p-4"
          scrolldelay="1"
        >
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
          <h1 className="text-white px-5">
            {" "}
            &#128516; {language === 'ar'
              ? 'ابتسامتك اولويتنا'
              : 'Your smile is our priority'}&#128516;
          </h1>
        </marquee>
        <div className="smile-section-body p-md-5 pb-md-0 m-0 p-3 text-white w-100 ">
          <p className="text-white">{language === 'ar'
            ? 'قبل وبعد'
            : 'After and befor'}</p>
          <h1>
            {language === 'ar'
              ? 'التحولات المدهشة التي قام فريق عاجي بإحداثها'
              : 'The amazing transformations achieved by the Aji team'}
          </h1>
          <div className="d-flex justify-content-center align-items-center my-5">
            <div className="col-11">
              <BeforeAfterComparison />
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="contain contact-section p-5 px-0 my-md-5 my-2">
        <div className="contact-head col-lg-6">
          <h3>{language == 'ar' ? 'احجز موعد' : 'Book an Appointment'}</h3>
          <h1 className="my-4">
            {language == 'ar'
              ? 'نحن هنا لخدمتك وللإهتمام بصحة أسنانك.'
              : 'We are here to serve you and take care of your dental health.'}
          </h1>
          <p>
            {language == 'ar'
              ? '(ديسمبر 2018) لوريم إيبسوم (بالإنجليزية: Lorem ipsum) هو نص نائب يستخدم عادة في صناعات الرسم والطباعة والنشر لمعاينة التخطيطات البصرية'
              : '(December 2018) Lorem ipsum is a placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.'}
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ContactForm />
          </div>
          <div className="col-md-6">
            <MapAndInfo />
          </div>
        </div>
      </section>

    </section>
  );
};






const MapAndInfo = () => (
  <>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7255.5203777315!2d46.6453508955836!3d24.597471117674516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1a9608317ecf%3A0xef69cdac5dc6c3dc!2sDhahrat%20Al%20Badi&#39;ah%2C%20Riyadh%2012784%2C%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1722941268008!5m2!1sen!2seg"
      width="100%"
      height="450"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>

    <div className="info-box">
      <div className="w-75 m-auto">
        <div className="row g-1 align-items-center">
          <div className="col-auto">
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
          </div>
          <div className="col d-flex justify-content-center align-item-center flex-column">
            <h6>البديعة، الرياض 12784</h6>
            <h6>الرياض-حي النخيل طريق المدينة المنورة ظهرة</h6>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Services;
