import React from 'react';
import CompareImage from 'react-compare-image';
import beforeImage from '../../assets/images/after.jpg';
import afterImage from '../../assets/images/before.jpg';
import './BeforeAfterComparison.modul.css';

const BeforeAfterComparison = () => {
  return (
    <div className="comparison-container" style={{ maxHeight: "700px ",maxWidth:"1400px" }}>
      <CompareImage
        leftImage={beforeImage}
        rightImage={afterImage}
        handle={
          <button className="custom-handle-button ">
            <i className="fas fa-arrows-alt-h"></i>
          </button>
        } 
      />
    </div>
  );
};

export default BeforeAfterComparison;
