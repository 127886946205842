import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider.modul.css'
import opinier from '../../assets/images/Image.png'
import useGetData from '../APIs/useGetData';
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';
import Loader from '../Loader/Loader';
export default function TextSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };
  const { language } = useLanguage()
  const { getData, getLoading } = useGetData()
  const [errs, setErrs] = useState(null)
  const [data, setData] = useState([])
  useEffect(() => {
    getData(`${API_URL}sayings`)
      .then(res => setData(res))
      .catch(err => err)
  }, [])
  if (errs) return <div className="alert alert-danger text-center">{errs.message || "Some thing wrong happened"}</div>

  return (
    <div className="text-slider-container text-center">
      <span>{`${language == 'ar' ? "شهادات عملاؤنا" : "Our clients' testimonials"}`}</span>
      <h2> {`${language == 'ar' ? "آراء عملاؤنا في خدماتنا " : "Our customers' opinions about our services"}`}</h2>
      <Slider {...settings}>
        {getLoading ? <Loader /> :
          data.map((opinion,ind) => {
            return (
              <div className="slide" key={ind}>
                <div className="opinion d-flex flex-column justify-content-center align-items-center text-center w-50 m-auto p-lg-5">

                  <ul className='d-flex'>
                    <li><i className='fas fa-star'></i></li>
                    <li><i className='fas fa-star'></i></li>
                    <li><i className='fas fa-star'></i></li>
                    <li><i className='fas fa-star'></i></li>
                    <li><i className='fas fa-star'></i></li>
                  </ul>
                  <p>{opinion.client_saying}</p>
                  <div className="opinier-img w-100  ">
                    <img src={opinion.profile_photo_path||opinier} className='img-fluid ' alt="opinion" />
                  </div>
                  <h5 className='fw-bold mb-0'> {opinion.user_name}</h5>
             
                </div>
              </div>
            )
          })
        }




      </Slider>
    </div>
  );
}
