import React, { useEffect, useState } from 'react';
import x from '../../../assets/images/whyusimg.png';
import Loader from '../../Loader/Loader';
import { useLanguage } from '../../Contexts/LanguageContext';
import BackText from '../../../BackText/BackText';

export default function WhyUs({ homeData }) {
    const [data, setData] = useState(null);
    const { language } = useLanguage()
    useEffect(() => {
        if (homeData && homeData.sections && homeData.sections.length > 1) {
            setData(homeData.sections[1]);
        }
    }, []);

    return (
        <section className='why-us py-md-5'>
            <div className="row my-md-5 pt-md-5 my-3 d-flex justify-content-between">
                {data ? (
                    <>
                        <div className="col-lg-6  flex-column justify-content-center my-3 p-3">
                            <div className="">
                                <h3 className='borderBottom  fw-600'>{data.sub_content}</h3>
                                {/* <BackText text={'من نحن'} /> */}

                            </div>

                            <h2 className='font-56 fw-600'>{data.title}</h2>
                            <p className='text-ParagrafColor fw-600'>
                                {data.content}
                            </p>
                            <div className="row g-4 ">
                                <ul className="d-flex flex-wrap row g-3">
                                    <li className="whyus d-flex  col-md-6">
                                        <h4 className="mb-0 fw-600">{`${language == 'ar' ? "أجهزة طب الأسنان المتطورة" : "State-of-the-art dental equipment"}`}</h4>
                                    </li>
                                    <li className="whyus d-flex  col-md-6">
                                        <h4 className="mb-0 fw-600">{`${language == 'ar' ? "متخصصون ماهرون ومؤهلون" : "Skilled and qualified professionals"}`}</h4>
                                    </li>
                                    <li className="whyus d-flex  col-md-6">
                                        <h4 className="mb-0 fw-600">{`${language == 'ar' ? "مجموعة كاملة من خدمات طب الأسنان" : "Full range of dental care"}`}</h4>
                                    </li>
                                    <li className="whyus d-flex  col-md-6">
                                        <h4 className="mb-0 fw-600">{`${language == 'ar' ? "أجواء مريحة" : "relaxing atmosphere"}`}</h4>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-lg-5  why-us-img">
                            <img src={data.images || x} className='w-100 img-fluid' alt="Why Us" />
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
        </section>
    );
}
