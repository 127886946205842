import React, { useEffect, useState } from 'react';
import useGetData from '../../APIs/useGetData';
import { API_URL } from '../../../config';
import imageInstead from '../../../assets/images/imageInstead.png';
import Loader from '../../Loader/Loader';
import { useLanguage } from '../../Contexts/LanguageContext';
import { Link } from 'react-router-dom';
import BackText from '../../../BackText/BackText';

export default function HomeBlogs() {
    const { getData } = useGetData();
    const [posts, setPosts] = useState([]);
    const [errs, setErrs] = useState([]);
    const { language } = useLanguage();

    useEffect(() => {
        getData(`${API_URL}posts?lang=${language}`)
            .then(res => setPosts(res.slice(0, 3)))
            .catch(err => setErrs(err));
    }, [language]);

    return (
        <>

            {posts?.length > 0 ? (
                <section className='home-blogs my-md-5 py-md-5 my-3 contain'>
                    <div className="team-header my-5 text-center position-relative">
                        <div className="postion-relative">
                            <BackText textAr={"المدونه"} textEn={'Blogs'} />
                            <h1 className='font-md-h2'>{language == "ar" ? "مدونات عاجي" : "Aaji Blogs"}</h1>
                        </div>
                        <p>
                            {language == "ar" ? "  آخر الاخبار بمجموعة عاجي لطب الاسنان  قريباً مجمع عاجي لطب الأسنان في الدمام ولمعرفة المزيد تابعوا حسابات التواصل وانتظروا أقوى العروض" : "Latest news from Aji Dental GroupComing soon Aji Dental Complex in Dammam. To learn more, follow the social media accounts and wait for the best offers."}
                        </p>
                        <div className='home-article-link d-flex align-items-center'>
                            <a href="articles"><span>{language == "ar" ? "المدونه" : "Blogs"}</span></a>
                            <i className='fas fa-arrow-left-long text-mainColor mx-2'></i>
                        </div>
                    </div>

                    <div className="row my-md-5 my-3 g-3">

                        <div className="row p-md-4 g-0">
                            {posts.map((post, ind) => (
                                <div className="col-lg-4 col-md-6 mb-4 m-0 p-md-3 " key={ind}>
                                    <div className="bg-white rounded-5 p-3 border h-100">
                                        <div className="article-post">
                                            <img
                                                src={post.cover_photo_path || imageInstead}
                                                className="w-100 border rounded-5"
                                                style={{ maxHeight: "200px", objectFit: "cover" }}
                                                alt={post.photo_alt_text || 'Article Image'}
                                            />
                                            <div className=" pe-0 my-3  p-3">
                                                {post.categories ?
                                                    post.categories.map((category, ind) => <Link key={ind} to={`/categoryposts/${category.id}`}>
                                                        <h5 className="article-headline text-mainColor">
                                                            {category.name}
                                                        </h5>
                                                    </Link>)
                                                    : ""}
                                                <h4>{post.title}</h4>
                                                <p>
                                                    {post.body.split(' ').slice(0, 30).join(' ')}...
                                                    <span> <Link to={`/postdetails/${post.id}`}>{language == "ar" ? "عرض المزيد" : "Show more"}</Link> </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                    </div>
                </section>
            ) : (
                <Loader />
            )}

        </>
    );
}
