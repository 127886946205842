import React, { useEffect, useState } from 'react';
import ad from '../../assets/images/dentist.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../APIs/usePostData';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';

export default function ForgetPassword() {
  const [responseMessage, setResponseMessage] = useState([])
  const { postData, loading, errors } = usePostData();
  const navigate = useNavigate(); // Initialize the navigate function
const {language}=useLanguage()


  // SUBMIT REGISTER =>AFTER VALIDATION WHAT DO YOU WANT TO DO (HERE FETCH)
  async function submitRegister(values) {
    try {
      const response = await postData(`${API_URL}forgot-password`, values);
      setResponseMessage(response); // Set the response message
      // Check if responseMessage.data exists and is empty
      if (!(typeof responseMessage.data === 'object')) {
        toast.success(`${language=='ar'?"لقد تم الارسال برجاء مراجعة الواتس  ":'Code sent successfuly to your whats!'}`);
        setTimeout(() => {
          navigate('/resetpassword'); // Uncomment this line to navigate to the login page after 2 seconds
        }, 2000); // Adjust the delay as needed
      } else {
        toast.error(`${language=='ar'?"فشل الارسال من فضلك اعد المحاوله ":'Failed to send. Please try again.'}`);
      }
    } catch (err) {
      setResponseMessage(err);
      toast.error(`${language=='ar'?"حدث خطأ ما":'An error occurred .'} : ${err.message} `); // Show an error message
    }
  }
  //FORM VALIDATION
  let validationSchema = yup.object({
  // FORM VALIDATION
  mobile: yup
    .number()
    .typeError(`${language=='ar'?"يجب ان يكون رقم":'Must be a number'}`) 
    .required(`${language=='ar'?"الحقل مطلوب":'Field required'}`)
  });

  //FORMIK OBJECT
  let formik = useFormik({
    initialValues: {
      mobile: '',
    },
    validationSchema,
    onSubmit: submitRegister
  });



  return (
    <section className='signup my-5 p-3'>
      <div className="contain">
        <div className="row">
          <div className="col-md-6">
            <h2> {language=="ar"?"ارسال كود تغيير كلمة المرور":"Send reset password code"}</h2>

            <form onSubmit={formik.handleSubmit}>
              {responseMessage ? <div className="text-danger ">{responseMessage?.data?.mobile}</div> : ""}
              {errors ? <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
                <div className="alert alert-danger">
                  Error loading data: {errors.message}
                </div>
              </div>
                : ""}

              <div className={`row ${language=='en'?"ltr":""}` }>
                {/* Problem here  */}
                <div className="col-md-6 my-2" >
                  <label className='text-dark' htmlFor="mobile">{language=="ar"?"رقم الهاتف":"Phone number"}</label>
                  <input
                    placeholder=""
                    type="text"
                    id='mobile'
                    name='mobile'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    className={`${formik.errors.mobile && formik.touched.mobile ? 'input-error alert alert-danger' : ''} ${language=='en bg-danget'?"ltr":"ltr"} `}
                  />
                  {formik.errors.mobile && formik.touched.mobile && (
                    <div className="text-danger ">{formik.errors.mobile}</div>
                  )}
                </div>
              </div>

              <div  className={`my-3 text-center ${language=='en'?"ltr":""}` }>
                {loading ? <button type='text' className='btn bg-mainColor text-white d-block py-3 disabled w-25' ><i className="fa-solid fa-spinner fa-spin"></i></button> :
                  <button type='submit' className='btn bg-mainColor text-white d-block py-3 w-25 ' disabled={!(formik.dirty && formik.isValid)}>{language=="ar"?"ارسال":"Send"}</button>}

              </div>
            </form>
          </div>
          <div className="col-md-5 me-auto">
            <img src={ad} className='w-100 h-100' alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />

    </section>
  )
}
