import React, { useContext, useEffect, useState } from 'react';
import './SignUp.modul.css';
import ad from '../../assets/images/dentist.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../APIs/usePostData';
import useGetData from '../APIs/useGetData';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input'; // Import the PhoneInput component
import 'react-phone-number-input/style.css'; // Import the styles for PhoneInput
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';
import ar from 'react-phone-number-input/locale/ar.json';
import { UserContext } from '../Contexts/UserContext';

export default function SignUp() {
  const [cities, setCities] = useState([])
  const [responseMessage, setResponseMessage] = useState([])
  const { postData, loading } = usePostData();
  const { getData, getLoading } = useGetData();
  const { language } = useLanguage()
  const [errs, setErrs] = useState([])
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { setToken } = useContext(UserContext);

  const navigate = useNavigate(); // Initialize the navigate function
  //FORMIK OBJECT
  //FORM VALIDATION
  let validationSchema = yup.object({
    first_name: yup.string()
      .max(25, `${language === 'ar' ? "اقصي حد للحروف 25" : "Max is 25 characters"}`)
      .min(3, `${language === 'ar' ? "اقل حد مسموح للحروف 3" : "Min is 3 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    last_name: yup.string()
      .max(25, `${language === 'ar' ? "اقصي حد للحروف 25" : "Max is 25 characters"}`)
      .min(3, `${language === 'ar' ? "اقل حد مسموح للحروف 3" : "Min is 3 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    user_name: yup.string()
      .max(25, `${language === 'ar' ? "اقصي حد للحروف 25" : "Max is 25 characters"}`)
      .min(3, `${language === 'ar' ? "اقل حد مسموح للحروف 3" : "Min is 3 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    age: yup.number()
      .typeError(`${language === 'ar' ? "يجب أن يكون الرقم" : "Must be a number"}`)
      .max(150, `${language === 'ar' ? "اقصي عمر 150  سنه" : "Max is 150 years"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    email: yup.string()
      .email(`${language === 'ar' ? "البريد الإلكتروني غير صحيح" : "Invalid email address"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    mobile: yup.string()
      .max(20, `${language === 'ar' ? "ادخل رقم صحيح اكثر عدد 20 رقم" : "Enter valid phone ,Max 20 numbers"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    city_id: yup.string()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    marital_status: yup.string()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),
    password: yup.string()
      .min(8, `${language === 'ar' ? "يجب ان يكون علي الاقل 8 حروف" : "Must be at least 8 characters"}`)
      .max(30, `${language === 'ar' ? "يجب ان يكون علي الأكثر 30 حرف" : "Must be less than 30 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),
    password_confirmation: yup.string()
      .oneOf([yup.ref("password"), null], `${language === 'ar' ? "الباسورد يجب ان يتطابق مع إعادة الباسورد" : "Passwords must match"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`)
  });

  // Concatenate country code and phone number, then update Formik's `mobile` value
  const handlePhoneChange = () => {
    const formattedPhone = phoneNumber?.startsWith('0') ? phoneNumber?.substring(1) : phoneNumber;
    const combinedPhone = `${countryCode}${formattedPhone}`;
    formik.setFieldValue('mobile', combinedPhone); // Update formik's `mobile` value with the combined phone number
  };


  useEffect(() => {
    try {

      getData(`${API_URL}cities?lang=${language}`)
        .then(response => setCities(response))
        .catch(err => setErrs(err))
    } catch (err) {
      setErrs(err)
    }
  }, [])
  // SUBMIT REGISTER =>AFTER VALIDATION WHAT DO YOU WANT TO DO (HERE FETCH)
  async function submitRegister(values) {

    try {
      const response = await postData(`${API_URL}register?lang=${language}`, values);
      setResponseMessage(response); // Set the response message
      if (response.status == 'error') {
        setErrs(response.data)

        toast.error(`${response.data || language == 'ar' ? 'فشل التسجيل من فضلك اعد المحاوله' : 'Failed to register. Please try again.'}`);

      } else {
        if (response?.code == 422) {
          setErrs(response.msg)
          toast.error(`هناك مشكله`);

        }
        else if (response?.code == 500) {
          setErrs(response.msg)
        } else {

          localStorage.setItem('token', response.token);
          // Save the token in context
          setToken(response.token);

          // Check if responseMessage.data exists and is empty
          toast.success(`${language == 'ar' ? "تم التسجيل بنجاح" : "Register success!"}`);
          setTimeout(() => {
            // navigate('/profile'); // Uncomment this line to navigate to the login page after 2 seconds
          }, 2000); // Adjust the delay as needed
        }



      }
    } catch (err) {

      setResponseMessage(err.response.data);
      setErrs(err);
      toast.error(`${language == 'ar' ? "حدث خطأ ما" : "Error happened"}`);
      toast.error(err?.message);
    }

  }

  let formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      age: '',
      city_id: '',
      marital_status: '',
      user_name: '',
      email: '',
      mobile: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit: submitRegister
  });

  // if (errs) return <div className="alert alert-danger text-center">{errs.message || "Some thing wrong happened"}</div>



  return (
    <section className='signup my-5 p-3'>
      <div className="contain">
        <div className="row">
          <div className="col-md-6">
            <span>{language == 'ar' ? "أهلا بك" : "Welcome"}</span>
            <h2> {language == 'ar' ? "انشاء حساب" : "Sign up"} </h2>

            <form onSubmit={formik.handleSubmit}>
              {/* Display Server-Side Validation Errors */}
              {errs ?
                Object.entries(errs).map(([field, messages], index) => (
                  <div key={index} className="error-item">
                    <ul>
                      {messages?.map((message, i) => (
                        <li key={i} className="text-danger">{message}</li>
                      ))}
                    </ul>
                  </div>
                )) : ""}
              <div className="row">
                {/* FNAME */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="first_name"> {language == 'ar' ? "الاسم الاول" : "First name"}</label>
                  <input
                    placeholder="مثال محمد"
                    type="text"
                    id='first_name'
                    name='first_name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    className={formik.errors.first_name && formik.touched.first_name ? 'input-error alert alert-danger' : ''}
                  />
                  {formik.errors.first_name && formik.touched.first_name && (
                    <div className="text-danger ">{formik.errors.first_name}</div>
                  )}
                </div>
                {/* LNAME */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="last_name"> {language == 'ar' ? "الاسم الاخير" : "Last name"}</label>
                  <input

                    type="text"
                    id='last_name'
                    name='last_name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                    className={formik.errors.last_name && formik.touched.last_name ? 'input-error alert alert-danger' : ''}
                  />
                  {formik.errors.last_name && formik.touched.last_name && (
                    <div className="text-danger">{formik.errors.last_name}</div>
                  )}
                </div>
                {/* USERNAME */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="user_name"> {language == 'ar' ? "اسم المستخدم" : "User name"}</label>
                  <input
                    placeholder={`${language == 'ar' ? "مثال فياض" : "ex:fayyad"}`}
                    type="text"
                    id='user_name'
                    name='user_name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.user_name}
                    className={formik.errors.user_name && formik.touched.user_name ? 'input-error alert alert-danger' : ''}
                  />
                  {formik.errors.user_name && formik.touched.user_name && (
                    <div className="text-danger ">{formik.errors.user_name}</div>
                  )}
                </div>
                {/* EMAIL */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="email"> {language == 'ar' ? "الايميل" : "Email"}</label>
                  <input
                    placeholder=""
                    type="email"
                    id='email'
                    name='email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={formik.errors.email && formik.touched.email ? 'input-error alert alert-danger' : ''}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="text-danger ">{formik.errors.email}</div>
                  )}
                </div>
                {/* MOBILE */}

                {/* Country Code Input */}
                <div className="col-md-2 my-2 ps-3"

                >
                  <label>  {language == 'ar' ? "الكود " : "Code"}</label>
                  <PhoneInput
                    international
                    value={countryCode}
                    defaultCountry="SA" // Default country for the phone input
                    onChange={(code) => {
                      setCountryCode(code); // Update country code state
                      handlePhoneChange(); // Update Formik's `mobile` value
                    }}
                    className="form-control p-13 "
                    placeholder="Country Code"
                  />
                </div>

                {/* Phone Number Input */}
                {/* <div className="col-md-4 my-2 p-1">
                  <label className='text-dark' htmlFor="mobile">{language == 'ar' ? "رقم الهاتف" : "Phone number"}</label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value); // Update phone number state
                      handlePhoneChange(); // Update Formik's `mobile` value
                    }}
                    onBlur={formik.handleBlur}
                    className={formik.errors.password && formik.touched.password ? 'input-error form-control p-3  alert alert-danger' : 'form-control p-3'}

                    placeholder="1234567890"
                  />
                  {formik.errors.mobile && formik.touched.mobile && (
                    <div className="text-danger">{formik.errors.mobile}</div>
                  )}
                </div> */}
                <div className="col-md-4 my-2">
                  <label className='text-dark' htmlFor="mobile">{language == 'ar' ? "رقم الهاتف" : "Phone number"}</label>
                  <input
                    id='mobile'
                    name='mobile'
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value); // Update phone number state
                      handlePhoneChange(); // Update Formik's `mobile` value
                    }}
                    onBlur={formik.handleBlur}
                    className={formik.errors.mobile && formik.touched.mobile ? 'input-error form-control p-3  alert alert-danger' : 'form-control p-3'}
                  />
                  {formik.errors.mobile && formik.touched.mobile && (
                    <div className="text-danger">{formik.errors.mobile}</div>
                  )}
                </div>
                {/* AGE */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="age">{language == 'ar' ? "العمر" : "Age"}</label>
                  <input
                    min={3}
                    max={150}
                    placeholder=""
                    type="text"
                    id='age'
                    name='age'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.age}
                    className={formik.errors.age && formik.touched.age ? ' alert alert-danger input-error' : ''}
                  />
                  {formik.errors.age && formik.touched.age && (
                    <div className="text-danger ">{formik.errors.age}</div>
                  )}
                </div>
                {/* CITY */}
                <div className="form-row mb-md-4 col-md-6 my-2">
                  <div className="form-group">
                    <label className='text-dark' htmlFor="city_id">
                      {language == 'ar' ? "المدينه" : "City"}
                    </label >
                    <select
                      className={formik.errors.city_id && formik.touched.city_id ? 'input-error form-select alert alert-danger' : 'form-select'}

                      id='city_id'
                      name='city_id'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city_id}
                    >

                      <option value="" disabled >{language == 'ar' ? "اختر المدينه" : "Choose country"}</option>
                      {cities.map((city, ind) => <option value={city?.id} key={ind}>{city?.name}</option>)}
                    </select>
                    {formik.errors.city_id && formik.touched.city_id && (
                      <div className="text-danger">{formik.errors.city_id}</div>
                    )}
                  </div>
                </div>

                {/* MARITAL STATUS */}

                <div className="form-row mb-md-4 col-md-6 my-2">
                  <div className="form-group">
                    <label className='text-dark' htmlFor="marital_status"> {language == 'ar' ? "الحاله الاجتماعيه" : "Marital status"}</label>
                    <select
                      className={formik.errors.marital_status && formik.touched.marital_status ? 'input-error form-select alert alert-danger' : 'form-select'}
                      id='marital_status'
                      name='marital_status'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.marital_status}
                    >
                      <option value="" disabled>{language === 'ar' ? "اختر الحاله" : "Select status"}</option>
                      <option value='single' defaultValue>{language === 'ar' ? "اعزب" : "Single"}</option>
                      <option value='married'>{language === 'ar' ? "متزوج" : "Married"}</option>
                    </select>
                    {formik.errors.marital_status && formik.touched.marital_status && (
                      <div className="text-danger">{formik.errors.marital_status}</div>
                    )}
                  </div>
                </div>


                {/* PASSWORD */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="password">{language == "ar" ? "كلمة السر" : "Password"}</label>
                  <input
                    placeholder=""
                    type="password"
                    id='password'
                    name='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={formik.errors.password && formik.touched.password ? 'input-error  alert alert-danger' : ''}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="text-danger ">{formik.errors.password}</div>
                  )}
                </div>
                {/* PASSWORD CONFIRMATION */}
                <div className="col-md-6 my-2">
                  <label className='text-dark' htmlFor="password">{language == "ar" ? " تأكيد كلمة السر" : "Confirm Password"}</label>
                  <input

                    type="password"
                    id='password_confirmation'
                    name='password_confirmation'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password_confirmation}
                    className={formik.errors.password_confirmation && formik.touched.password_confirmation ? 'input-error  alert alert-danger' : ''}
                  />
                  {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                    <div className="text-danger">{formik.errors.password_confirmation}</div>
                  )}
                </div>


              </div>

              <div className="my-3 text-center">
                {loading ? <button type='text' className='btn bg-mainColor text-white d-block py-3  w-25' ><i className="fa-solid fa-spinner fa-spin"></i></button> :
                  <button type='submit' className='btn bg-mainColor text-white d-block py-3 w-25 '>{language == 'ar' ? "انشاء" : "Create"}</button>}

                <h5 className='mt-1'>{language == 'ar' ? "هل لديك حساب ؟" : "Do you have an account ?"}<a href="login" className='btn text-mainColor'>تسجيل الدخول</a></h5>
              </div>
            </form>






















          </div>
          <div className="col-md-6 me-auto  d-flex justify-content-center align-items-center ">
            <img src={ad} className='w-100 object-fit-cover' alt="" />
          </div>
        </div>
      </div>
      <ToastContainer />

    </section>
  )
}
