import React, { useContext, useEffect, useState } from 'react';
import './Home.modul.css'

import useGetData from '../APIs/useGetData';
import { API_URL } from '../../config';
import Header from './HomeSections/Header';
import WhoAreWe from './HomeSections/WhoAreWe';
import Loader from '../Loader/Loader';
import HomeBlogs from './HomeSections/HomeBlogs';
import ServicesBody from '../Services/ServicesBody';
import WhyUs from './HomeSections/WhyUs';
import CustomersOpinionSlider from './HomeSections/CustomersOpinionSlider';
import HomeMoreVideosSlider from './HomeSections/HomeMoreVideosSlider';
import HomeTeamSlider from './HomeSections/HomeTeamSlider';
import { useLanguage } from '../Contexts/LanguageContext';
import BackText from '../../BackText/BackText';
export default function Home() {
  const [homeData, setHomeData] = useState()
  const [homeErr, setHomeErr] = useState()
  const { getData, getLoading } = useGetData()
  const { language } = useLanguage(); 
  useEffect(() => {
    getData(`${API_URL}page/2?lang=${language}`)
      .then(res => setHomeData(res))
      .catch(err => setHomeErr(err))
  }, [language])
  if (getLoading) return <Loader />

  return (<div className='main-home'>
    <Header homeData={homeData} language={language} />
    <WhoAreWe homeData={homeData} />
    <div className="contain">
      <section>
        <div className="team-header my-5 text-center">
          <div className="position-relative">
            <h1>{language == "ar" ? "خبراء في مجال طب الاسنان" : "Dental experts"}</h1>
            <BackText textAr={'خدماتنا'} textEn={'Our services'}/>

          </div>
          <h3>{language == "ar" ? "الفريق الطبي بعيادات عاجي لطب الاسنان" : "Medical team at Ajay Dental Clinics"} </h3>
          <p>
            {language == "ar" ? " افضل عيادات لطب الأسنان في المملكه السعوديه.نحن نقدم جميع العلاجات عن طريق الفم بما في ذلك زراعة الأسنان" :
              "The best dental clinics in the Kingdom of Saudi Arabia. We provide all oral treatments including dental implants."}

          </p>
          <p>Gerek 3D , Panoramic X-Ray & Galileos CT Machine </p>
        </div>
        <ServicesBody homeData={homeData} />
      </section>
      <WhyUs homeData={homeData} />
      {/* TEAM HEADER */}
      {/* slider */}
      <HomeTeamSlider />
    </div>
    {/*  home-bottom-slider */}
    <HomeMoreVideosSlider />
    {/*  customers-opinion-slider */}
    <CustomersOpinionSlider />
    <HomeBlogs homeData={homeData} />
  </div>
  );

}




