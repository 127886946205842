import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Contexts/UserContext';
import useGetDataAuth from '../APIs/useGetDataAuth';
import placeholderImage from '../../assets/images/image42.png'; // Add a placeholder image for profile
import Loader from '../Loader/Loader';
import './Profile.modul.css'
import AlluserReservations from '../Reserve/AllUserReservations/AlluserReservations';
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';

export default function Profile() {
  const { token } = useContext(UserContext);
  const { getDataAuth, loading } = useGetDataAuth();
  const [userData, setUserData] = useState(null);
  const [errs, setErrs] = useState(null);
const {language}=useLanguage()
  // Retrieve token from localStorage if not present in context
  const storedToken = token || localStorage.getItem('token');

  useEffect(() => {
    try {
      if (storedToken) {
        getDataAuth(`${API_URL}profile`, storedToken)
          .then((res) => {
            setUserData(res);
          })
          .catch((err) => setErrs(err));
      }
    } catch (err) {
      setErrs({ err });
    }
  }, [storedToken]);
  

  return (
    <section className=" my-5">
      <div className="row justify-content-center">

        <div className="col-md-10  ">
          {!loading ?
            <div className="card shadow-sm   w-100">
              <div className="card-header w-100 text-white text-center bg-mainColor">
                <h2>{`${language=='ar'?"تعديل الملف الشخصي":"update profile"}`}</h2>
              </div>
              <div className="card-body w-100 text-center">
                <img
                  src={userData?.profile_photo_path || placeholderImage}
                  alt="Profile"
                  className="rounded-circle mb-3"
                  style={{ width: '130px', height: '130px', objectFit: 'cover' }}
                />
                <h4>{userData?.first_name} {userData?.last_name}</h4>
                <p className="text-muted">@{userData?.user_name}</p>
                <a href="/editprofile" className='btn bg-mainColor text-white'>{`${language=='ar'?"تعديل":"Edit"}`}</a>
                <hr />
                <div className="row d-flex justify-content-center align-items-center">
                  <p className='col-lg-3 col-md-6 text-dark'><strong>{`${language=='ar'?"الايميل:":"Email:"}`}</strong> {userData?.email}</p>
                  <p className='col-lg-3 col-md-6 text-dark'><strong>{`${language=='ar'?"رقم الهاتف:":"Phone number:"}`}</strong> {userData?.mobile}</p>
                  <p className='col-lg-3 col-md-6 text-dark'><strong>{`${language=='ar'?"المدينه:":"City:"}`}</strong> {userData?.city}</p>
                  <p className='col-lg-3 col-md-6 text-dark'><strong>{`${language=='ar'?"الحاله الاجتماعيه:":"Marital status:"}`}</strong> {userData?.marital_status}</p>
                </div>
             <AlluserReservations/>
              </div>
            </div>
            : <Loader />}
        </div>
      </div>
    </section>
  );
}
