import React, { useEffect, useState } from "react";
import useGetData from "../APIs/useGetData";
import { API_URL } from '../../config';

export default function SocialLinks() {
  const { getData, getLoading, getErrors } = useGetData();
  const [social, setSocial] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData(`${API_URL}social`)
      .then((res) => setSocial(res))
      .catch((err) => {

        setError(err);
      });
  }, []);

  if (getLoading) {
    return <ul className="d-flex justify-content-center m-0 list-style">

      <li className="mx-2">
        <a
          className=""
          href={''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fa-brands fa-facebook`} aria-hidden="true"></i>
        </a>
      </li>
      <li className="mx-2">
        <a
          className=""
          href={''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fa-brands fa-youtube`} aria-hidden="true"></i>
        </a>
      </li>
      <li className="mx-2">
        <a
          className=""
          href={''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fa-brands fa-linkedin`} aria-hidden="true"></i>
        </a>
      </li>
      <li className="mx-2">
        <a
          className=""
          href={''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fa-brands fa-twitter`} aria-hidden="true"></i>
        </a>
      </li>

    </ul>
  }

  if (error) {
    // Checking if it's a server error
    if (error.response && error.response.status >= 500) {
      return <div>Internal Server Error. Please try again later.</div>;
    }
    // Other errors (e.g., network issues)
    return <div> </div>;
  }

  if (!social || social.length === 0) {
    return <div>No social links available.</div>;
  }

  return (
    <ul className="d-flex justify-content-center m-0 list-style">
      {social.map((ic, ind) => (
        <li key={ind} className="mx-2">
          <a
            className=""
            href={ic.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`fa-brands fa-${ic.name}`} aria-hidden="true"></i>
          </a>
        </li>
      ))}
    </ul>
  );
}
