import React from 'react'
import { Link } from 'react-router-dom'
import './Button.modul.css'
export default function Button({text,to}) {
  return (
    <div className="button-container">
      <Link to={to} className="button rounded-2">
        {text}
      </Link>
    </div>
  )
}
