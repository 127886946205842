import React from "react";
import "./Team.modul.css";
import DoctorCard from "../DoctorCard/DoctorCard";
import doctor1 from '../../assets/images/Doctor01.png'
import doctor2 from '../../assets/images/Doctor02.png'
import doctor3 from '../../assets/images/Doctor03.png'
import doctor4 from '../../assets/images/Doctor04.png'
import doctor5 from '../../assets/images/Doctor05.png'
import { useLanguage } from "../Contexts/LanguageContext";
export default function Team() {
  const { language } = useLanguage()

  return (
    <section className="team">
      <div className="contain">
        {/* START HEAD */}
        <div className="team-header my-5 text-center">
          <h1> {language == 'ar' ? "خبراء في مجال طب الاسنان" : "Experts in the field of medical dentistry"}</h1>
          <h3>{language == 'ar' ? " الفريق الطبي بعيادات عاجي لطب الاسنان" : "Medical team at Ajay Dental Clinics"}</h3>
          <p>{language == 'ar' ? "افضل عيادات لطب الأسنان في المملكه السعوديه.نحن نقدم جميع العلاجات  عن طريق الفم بما في ذلك زراعة الأسنان" : "The best dental clinics in the Kingdom of Saudi Arabia. We provide all oral treatments including dental implants."}
          </p>
          <p>Gerek 3D , Panoramic X-Ray & Galileos CT Machine </p>
      
        </div>
        {/* END HEAD */}
        <div className="team-body my-md-5">
          <div className="row">
            <div className="col-lg-3 my-md-5 text-center">
              <DoctorCard

                doctorImg={doctor1}
                name={language == 'ar' ? "دكتور/محمد السيد" : "Doctor:Mohammed Elsayyed"}
                job={language=='ar'?"أخصائية تقويم الأسنان":"Orthodontist"}

              />
            </div>
            <div className="col-lg-3 my-md-5 text-center">
              <DoctorCard
                doctorImg={doctor2}
                name={language == 'ar' ? "دكتور/محمد السيد" : "Doctor:Mohammed Elsayyed"}
                job={language=='ar'?"أخصائية تقويم الأسنان":"Orthodontist"}

              />
            </div>
            <div className="col-lg-3 my-md-5 text-center">
              <DoctorCard
                doctorImg={doctor3}
                name={language == 'ar' ? "دكتوره/مها السيد" : "Doctor:Maha Elsayyed"}
                job={language=='ar'?"أخصائية تقويم الأسنان":"Orthodontist"}

              />
            </div>
            <div className="col-lg-3 my-5 text-center">
              <DoctorCard
                doctorImg={doctor4}
                name={language == 'ar' ? "دكتور/محمد السيد" : "Doctor:Mohammed Elsayyed"}
                job={language=='ar'?"أخصائية تقويم الأسنان":"Orthodontist"}
              />
            </div>
            <div className="col-lg-3 my-5 text-center">
              <DoctorCard
                doctorImg={doctor5}
                name={language == 'ar' ? "دكتور/محمد السيد" : "Doctor:Mohammed Elsayyed"}
                job={language=='ar'?"أخصائية تقويم الأسنان":"Orthodontist"}

              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
