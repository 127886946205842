import { useState } from 'react';
import axios from 'axios';

export default function useGetData() {
    const [getLoading, setGetLoading] = useState(false);  // Initially false
    const [getErrors, setGetErrors] = useState(null);

    const getData = async (url) => {
        setGetLoading(true);
        setGetErrors(null);

        try {
            const response = await axios.get(url);
            return response.data.data ?response.data.data:response.data; // Or just return response if you need more data
        } catch (err) {
            setGetErrors(err);
            throw err; // Re-throw to handle errors in the calling component
        } finally {
            setGetLoading(false);
        }
    };

    return { getData, getLoading, getErrors };
}
