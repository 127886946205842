// src/Component/Layout/NavBar.js
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import './NavBar.modul.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import profile from '../../assets/images/image42.png';
import logo from '../../assets/images/footerlogo.png';
import { UserContext } from '../Contexts/UserContext';
import { useLanguage } from '../Contexts/LanguageContext'; // Import the useLanguage hook
import i18n from '../locales/i18n'
import useGetDataAuth from '../APIs/useGetDataAuth';
import { API_URL } from '../../config';
export default function NavBar() {
  const { language, toggleLanguage } = useLanguage(); // Get the language and toggle function from context
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const { token, setToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { getDataAuth, loading } = useGetDataAuth();
  const [userData, setUserData] = useState(null);
  const [errs, setErrs] = useState(null);
  // Retrieve token from localStorage if not present in context
  const storedToken = token || localStorage.getItem('token');

  useEffect(() => {
    try {
      if (storedToken) {
        getDataAuth(`${API_URL}profile`, storedToken)
          .then((res) => {
            setUserData(res);
          })
          .catch((err) => setErrs(err));
      }
    } catch (err) {
      setErrs({ err });
    }
  }, [storedToken]);

  // NAV COLLAPSE
  const handleNavItemClick = () => {
    setIsNavCollapsed(true); // Close the navbar on item click
  };
  const handleToggle = () => {
    setIsNavCollapsed(!isNavCollapsed); // Toggle the navbar collapse state
  };



  const changeLanguage = (lng) => { i18n.changeLanguage(lng); }

  // LOG OUT
  function logout() {
    localStorage.removeItem('token');
    setToken(null);
    navigate('/');
  }

  return (
    <nav className="navbar navbar-expand-lg contain p-md-5">
      <button
        className="navbar-toggler custom-toggler m-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded={!isNavCollapsed}
        aria-label="Toggle navigation"
        onClick={handleToggle}
      >
        <i className="fa fa-bars"></i>
      </button>
      <NavLink className="nav-link mx-3" to="/" onClick={handleNavItemClick}>
        <img src={logo} className='img-fluid logo' alt="" />
      </NavLink>
      <div className={`collapse navbar-collapse ${isNavCollapsed ? 'collapse' : 'show'}`} id="navbarSupportedContent">


        <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-center">
          <li className="nav-item">
            <NavLink className="nav-link" to="/" onClick={handleNavItemClick}>
              {language === 'ar' ? 'الرئيسيه' : 'Home'}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/about" onClick={handleNavItemClick}>
              {language === 'ar' ? 'من نحن' : 'About Us'}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/services" onClick={handleNavItemClick}>
              {language === 'ar' ? 'الخدمات' : 'Services'}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/articles" onClick={handleNavItemClick}>
              {language === 'ar' ? 'المقالات' : 'Articles'}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/team" onClick={handleNavItemClick}>
              {language === 'ar' ? 'فريق الاطباء' : 'Our Team'}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact" onClick={handleNavItemClick}>
              {language === 'ar' ? 'تواصل معنا' : 'Contact Us'}
            </NavLink>
          </li>

        </ul>

        <div className="d-flex justify-content-between align-items-center">
          <button className="ms-1 btn text-white border-0  py-3" onClick={toggleLanguage}>
            {language === 'ar' ? ' EN ' : ' AR '}
            <FontAwesomeIcon icon={faGlobe} />
          </button>

          {token ? (
            <li className="nav-item dropdown profile list-unstyled">
              <img src={userData?.profile_photo_path||profile} alt="" className='profile-img nav-link dropdown-toggle' role="button" data-bs-toggle="dropdown" aria-expanded="false" />
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/profile">{language === 'ar' ? 'ملف الشخصي' : 'Profile'}</a></li>
                <li><a className="dropdown-item" onClick={logout} href="#">{language === 'ar' ? 'تسجيل خروج' : 'Logout'}</a></li>
              </ul>
            </li>
          ) : (<>
            <Link className="btn border-0 px-5 py-3 mx-lg-4 text-white" to="/login" onClick={() => changeLanguage(language)}>
              {language === 'ar' ? 'تسجيل الدخول' : 'Login'}
            </Link>

          </>
          )}
        </div>
      </div>
    </nav>
  );
}
