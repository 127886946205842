import React, { useContext } from "react";
import footerLogo from "../../assets/images/footerlogo.png";
import { Link } from "react-router-dom";
import "./Footer.modul.css";
import SocialIcons from "../SocialIcons/SocialIcons";
import { useLanguage } from '../Contexts/LanguageContext'
import { UserContext } from "../Contexts/UserContext";
export default function Footer() {
  const { language } = useLanguage()
  const { token } = useContext(UserContext)
  const storedToken = token || localStorage.getItem("token")
  return (
    <footer className="footer py-md-5 pb-md-4 py-3 text-white">
      <div className="contain  ">
        <div className="row ">
          <div className="footer-col col-lg-3  mb-4 text-center">
            <img src={footerLogo} className="w-100 px-5 ms-0 footer-logo" alt="aaji dental group" />
            <h5 className="m-3 footer-welcome-p">

              {language == 'ar' ? "   نحن نرحب بكم فى الموقع الإلكترونى لمجموعة عاجي لطب الأسنان و عند  زيارتكم لنا، سوف تجدون كل الرعاية و العناية من الفريق الطبى المؤهل تماما لعلاج أسنانكم بدون الم فى عدة تخصصات مثل تجميل الأسنان، علاج  أسنان الأطفال، زرع الأسنان،تقويم الأسنان." : "We welcome you to the website of the Aji Dental Group, and when you visit us, you will find all the care and attention from the medical team that is fully qualified to treat your teeth without pain in several specialties such as cosmetic dentistry, children’s dental treatment, dental implants, and orthodontics."}
            </h5>
            <div className="social-ic">
              <SocialIcons />
            </div>
          </div>


          <div className="footer-col col-lg-2 col-md-6 mb-4 d-flex flex-column justify-content-between   " >
            <h4>{language == "ar" ? "الحساب" : "Account"}</h4>
            <h5>
              {token ? <Link to="/profile">{language == 'ar' ? "حسابي" : "my account"}</Link> : ""}
            </h5>
            <h5>
              <Link to="/login">{language == 'ar' ? "تسجيل الدخول/التسجيل" : "Login/Sign up"}</Link>
            </h5>
            <h5>
              <Link to="/">{language == 'ar' ? "الفريق" : "Team"}</Link>
            </h5>
            <h5>
              <Link to="/contact">{language == "ar" ? "تواصل معنا" : "contact"}</Link>
            </h5>
          </div>
          <div className="footer-col col-lg-2 col-md-6 mb-4 d-flex flex-column justify-content-between   " >
            <h4>{language == "ar" ? "الحساب" : "Account"}</h4>
            <h5>
              {token ? <Link to="/profile">{language == 'ar' ? "حسابي" : "my account"}</Link> : ""}

            </h5>
            <h5>
              <Link to="/login">{language == 'ar' ? "تسجيل الدخول/التسجيل" : "Login/Sign up"}</Link>
            </h5>
            <h5>
              <Link to="/">{language == 'ar' ? "الفريق" : "Team"}</Link>
            </h5>
            <h5>
              <Link to="/contact">{language == "ar" ? "تواصل معنا" : "contact"}</Link>
            </h5>
          </div>
          <div className="footer-col col-lg-2 col-md-6 mb-4 d-flex flex-column justify-content-between   " >
            <h4>{language == "ar" ? "الحساب" : "Account"}</h4>
            <h5>
              {token ? <Link to="/profile">{language == 'ar' ? "حسابي" : "my account"}</Link> : ""}

            </h5>
            <h5>
              <Link to="/login">{language == 'ar' ? "تسجيل الدخول/التسجيل" : "Login/Sign up"}</Link>
            </h5>
            <h5>
              <Link to="/">{language == 'ar' ? "الفريق" : "Team"}</Link>
            </h5>
            <h5>
              <Link to="/contact">{language == "ar" ? "تواصل معنا" : "contact"}</Link>
            </h5>
          </div>
          <div className="footer-col col-lg-2 col-md-6 mb-4 d-flex flex-column justify-content-between   " >
            <h4>{language == "ar" ? "الحساب" : "Account"}</h4>
            <h5>
              {token ? <Link to="/profile">{language == 'ar' ? "حسابي" : "my account"}</Link> : ""}

            </h5>
            <h5>
              <Link to="/login">{language == 'ar' ? "تسجيل الدخول/التسجيل" : "Login/Sign up"}</Link>
            </h5>
            <h5>
              <Link to="/">{language == 'ar' ? "الفريق" : "Team"}</Link>
            </h5>
            <h5>
              <Link to="/contact">{language == "ar" ? "تواصل معنا" : "contact"}</Link>
            </h5>
          </div>

        </div>
      </div>

      <div className="bottom">
        <hr />
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-2 d-flex flex-column justify-content-between">
              <a href="https://serv5.com" target="_blank">Serv5.com</a>
            </div>
            <div className="col-md-3">
              <h5>{language == 'ar' ? "حقوق النشر © 2024. كل الحقوق محفوظة." : "Copyright © 2024. All rights reserved."}</h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
