import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';

export default function ProtectIfAuthed({ children }) {
    const { token } = useContext(UserContext);
      // Retrieve token from localStorage if not present in context
  const storedToken = token || localStorage.getItem('token');
  
  if (storedToken) {
    // If user is not authenticated, redirect to login
    return <Navigate to="/profile" replace />;
  }

  // If user is authenticated, render the protected route's children
  return children;
}
