import React, { useContext, useState } from 'react';
import './Login.modul.css';
import ad from '../../assets/images/dentist.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../APIs/usePostData';
import { UserContext } from '../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';

export default function Login() {
  const { postData, loading, error } = usePostData();
  const [errs, setErrs] = useState(null)
  const { setToken } = useContext(UserContext);
  const navigate = useNavigate();
const{language}=useLanguage()
  const validationSchema = yup.object({
    login: yup.string().required(`${language=="ar"?"الحقل مطلوب":'Field required'}`),
    password: yup.string().min(8,`${language=="ar"?"يجب ان يكون علي الاقل 8 حروف":'Must be at least 8 char'}`).required(`${language=="ar"?"الحقل مطلوب":'Field required'}`),
  });

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema,
    onSubmit: submitRegister,
  });

  async function submitRegister(values) {
    try {
      const response = await postData(`${API_URL}login?lang=${language}`, values);
      if (response.code == 400 || response.code == 401) {
        setErrs(response)}
      else if (response.code == 200 || response.code == 201) {
        localStorage.setItem('token', response.token);
        // Save the token in context
     
          // Navigate to the profile page
        navigate('/profile');
        } else {
        // Save the token in localStorage
        // localStorage.setItem('token', response.token);
        // Save the token in context
        setToken(response.token);
        setErrs(response)
        if(response.token){
          localStorage.setItem('token', response.token);
          // Save the token in context
          setToken(response.token);    
            // Navigate to the profile page
          navigate('/profile');
        }
        // Navigate to the profile page
        // navigate('/profile');
      }
    } catch (err) {
    }
  }




  return (
    <section className="signup my-5 p-3">
      <div className="contain">
        <div className="row">
          <div className="col-md-6">
            <span> {language=="ar"?"أهلا بك":"Welcome "}</span>
            <h2> {language=="ar"?"انشاء حساب":"Sign up"}</h2>
            {error ? <div className='alert alert-danger'>{error?.response?.data?.message||"some thing wrong happened"}</div> : ""}
            {errs ? <div className='alert alert-danger'>{language=="ar"?"الاسم او الرقم السري غير صحيح":"Name or password is not correct"}</div> : ""}
          
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                {/* PHONE NUM */}
                <div className=" my-2 ">
                  <label className={`text-dark ${language=="en"?"ltr":""}`} htmlFor="login">
                    {language == 'ar' ? "   رقم الهاتف  بكود الدوله او اسم المستخدم او الايميل "   : "Phone number or user name or Email"}
                  </label>
                  <input
                    placeholder=""
                    type="text"
                    id="login"
                    name="login"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.login}
                    className={
                      formik.errors.login && formik.touched.login
                        ? 'input-error alert alert-danger'
                        : ''
                    }
                  />
                  {formik.errors.login && formik.touched.login && (
                    <div className="text-danger">
                      {formik.errors.login}
                    </div>
                  )}
                </div>
                
                {/* PASS */}
                <div className=" my-2">
                  <label className={`text-dark ${language=="en"?"ltr":""}`} htmlFor="password">
                    {language=="ar"?"كلمة السر":"Password "}
                  </label>
                  <input
                    placeholder=""
                    type="password"
                    id="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={
                      formik.errors.password && formik.touched.password
                        ? 'input-error alert alert-danger'
                        : ''
                    }
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                </div>
                <div   className={`frogetPassword ${language=="en"?"ltr":""}`}>
                  <a href="forgetpassword" className='btn text-mainColor bg-hover-trans'>{language=="ar"?"هل نسيت الرقم السري ؟":"forgot password?"}</a>
                </div>
              </div>
              {/* LOGIN AND FORGET BUTTONS */}
              <div className={`my-3 text-center ${language=="en"?"ltr":""}`} >
                {loading ? (
                  <button
                    type="text"
                    className="btn bg-mainColor text-white d-block py-3 w-25"
                  >
                    <i className='fas fa-spinner fa-spin'></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn bg-mainColor text-white d-block py-3 w-25"
                    
                    disabled={!(formik.dirty && formik.isValid)}
                  >
                   
                    {language=="ar"?" تسجيل الدخول":"Log in"}
                  </button>
                )}

                <h5 className="mt-1">
                
                  {language =="ar"?"  ليس لديك حساب ؟":"Dont have an account ?"}
                  <a href="signup" className="btn text-mainColor">
                    {' '}
                    {language =="ar"?"  انشاء حساب":"Sign up ?"}
                    
                  </a>
                </h5>
              </div>
            </form>
          </div>
          <div className="col-md-5 me-auto">
            <img src={ad} className="w-100 h-100" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
