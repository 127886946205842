import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import useGetDataAuth from '../../APIs/useGetDataAuth';
import Loader from '../../Loader/Loader';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../config';
import './ReservationDetails.modul.css';
import { useLanguage } from '../../Contexts/LanguageContext';

export default function ReservationDetails() {
  const [userData, setUserData] = useState(null);
  const { getDataAuth, loading } = useGetDataAuth();
  const { token } = useContext(UserContext);
  const { id } = useParams();
 const {language}= useLanguage()
  const storedToken = token || localStorage.getItem('token');

  useEffect(() => {
    const fetchReservationData = async () => {
      if (storedToken) {
        try {
          const res = await getDataAuth(`${API_URL}single-reservation/${id}`, storedToken);
          setUserData(res);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchReservationData();
  }, [storedToken, id]);

  if (loading) {
    return <Loader />;
  }

  if (!userData) {
    return <div className='alert alert-info text-center contain my-5'>{language == 'ar' ? "لا يوجد حجز" : "No reservations"}</div>;
  }

  const renderImages = (images) => (
    images.map((image, index) => (
      <img
        key={index}
        src={`${image}`}
        className="reservation-image"
        alt={`Medical Image ${index + 1}`}
      />
    ))
  );

  return (
    <section className="reservation-details-section">
      <div className="container text-center my-5">
        <h2 className="text-mainColor">معلومات الحجز</h2>
        <div className="reservation-info">
          <p><strong>الاسم:</strong> {userData.reservation.first_name} {userData.reservation.last_name}</p>
          <p><strong>يوم الحجز:</strong> {new Date(userData.reservation.reservation_day).toLocaleDateString()}</p>
        </div>

        <div className="row my-3 rounded-5">
          {/* MEDICAL INFORMATION */}
          <div className="col-12 col-md-4 mb-4 p-3">
            <div className="info-card bg-white shadow-sm rounded-5 border">
              <h3>معلومات طبية:</h3>
              {userData.medical.map((item, index) => (
                <div key={index} className="mb-3">
                  <strong>{item.title}</strong>
                  <div className="image-gallery">{renderImages(item.image)}</div>
                </div>
              ))}
            </div>
          </div>

          {/* XRAY INFORMATION */}
          <div className="col-12 col-md-4 mb-4 p-3">
            <div className="info-card bg-white shadow-sm rounded-5 border">
              <h3>الأشعة:</h3>
              {userData.xrays.map((xray, index) => (
                <div key={index} className="mb-3">
                  <strong>{xray.title}</strong>
                  <p>{xray.notes}</p>
                  <div className="image-gallery">{renderImages(xray.image)}</div>
                </div>
              ))}
            </div>
          </div>

          {/* MEDICINE INFORMATION */}
          <div className="col-12 col-md-4 mb-4 p-3">
            <div className="info-card bg-white shadow-sm rounded-5 border">
              <h3>الدواء:</h3>
              {userData.medicine_names.map((medicine, index) => (
                <div key={index} className="mb-3">
                  <strong>{medicine.name}</strong>
                  <p>{medicine.notes}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
