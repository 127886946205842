import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Contexts/UserContext';
import useGetDataAuth from '../APIs/useGetDataAuth';

export default function Xrays() {
  const [rays, setRays] = useState(null);
  const { getDataAuth, loading } = useGetDataAuth();
  const[err,setErr]=useState(null);
  const { token } = useContext(UserContext);
  // Retrieve token from localStorage if not present in context
  const storedToken = token || localStorage.getItem('token');
         // X-RAYS FETCHING
         useEffect(()=>{
             getDataAuth('${API_URL}xrayUser',storedToken)
             .then(res =>setRays(res))
             .catch(err => setErr(err))

         },[])
  return (
    <section className='text-center p-5 '>
        {err?<h4 className='text-danger'>You dont have any X-rays</h4>:"Xrays"}
    </section>
  )
}
