import React from 'react';
import doctorImage from '../../../assets/images/Home.png'; // Replace with actual image path
import Reserve from '../../Reserve/Reserve';
export default function Header({ homeData,language }) {
  // Extract the relevant data from homeData
  const { sections } = homeData|| {};
  const section = sections ? sections[0] : {}; // Get the first section

  return (
    <section className={`home   ${language=='en'?"me-md-5 ms-2 ":""}`}>
      <div className="row m-0 align-items-center justify-content-end">
        <div className="col-lg-7 me-auto padding-right-100 col-100" >
          {/* RIGHT SECTION */}
            <div className="content mb-md-5 "  style={{paddingTop:"50px"}}>
              <span>{section.sub_content }</span>
              <h2 className='content-h2'>{section.title }</h2>
              <p>{section.content}</p>
              
            </div>
          <Reserve  />
        </div>

        {/* LEFT SECTION */}
        <div className="col-lg-5 header-img p-0  position-relative">
          <img src={section.images||doctorImage} loading='lazy' className={`img-fluid w-100 h-100  `} alt="Doctor" />
          {/* <div className={`home-statistic ${language=='en'?"d-none":""} position-absolute  p-3 bottom-50 left-0 translate-middle-x`}>
            <div className="position-absolute home-static-num">
              <h4>1800</h4>
            </div>
            <div className="customers">
              <img src={hstatic} className='w-100' alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
