import { useFormik } from 'formik';
import * as yup from 'yup';
import usePostData from '../APIs/usePostData';
import useGetData from '../APIs/useGetData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReservationCard from './ReservationCard';
import './Reserve.modul.css'
import PhoneInput from 'react-phone-number-input'; // Import the PhoneInput component
import 'react-phone-number-input/style.css'; // Import the styles for PhoneInput
import { API_URL } from '../../config';
import { useLanguage } from '../Contexts/LanguageContext';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { addDays, isSameDay } from 'date-fns';










export default function Reserve() {
  ////////////////////////////////////////////
  const [cities, setCities] = useState([]);
  const [reserveTime, setReserveTime] = useState([]);
  const [reservationType, setReservationType] = useState([]);
  const [responseMessage, setResponseMessage] = useState(null);
  const [successed, setSuccessed] = useState(false);
  const [errs, setErrs] = useState(false);
  const { postData, loading, errors } = usePostData();
  const { getData, getLoading } = useGetData();
  const { language } = useLanguage()
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  // Concatenate country code and phone number, then update Formik's `mobile` value
  const handlePhoneChange = () => {
    const formattedPhone = phoneNumber?.startsWith('0') ? phoneNumber?.substring(1) : phoneNumber;
    const combinedPhone = `${countryCode}${formattedPhone}`;
    formik.setFieldValue('mobile', combinedPhone); // Update formik's `mobile` value with the combined phone number
  };


  // Define your specific allowed dates


  // Disable all dates except the allowed ones
  const isDateAllowed = (date) => {
    const selectedDay = getDay(date); // getDay returns 0-6 for Sunday-Saturday
    const today = new Date();
    const datey = new Date(selectedDay);

    // Format to YYYY-MM-DD
    const formattedDate = datey.toISOString().split('T')[0];

    // Disallow past dates
    if (date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
      return false;
    }

    // Convert the working days in reserveTime from Arabic to day numbers
    const allowedDays = reserveTime.map((day) => arabicDaysToNumbers(day?.working_days));

    // Check if the selected day is in the list of allowed days
    return allowedDays.includes(selectedDay);
  };
  // sunday is 0
  // monday is 1
  // 6 represents Saturday










  useEffect(() => {
    // CITIES FETCHING
    getData(`${API_URL}cities?lang=${language}`)
      .then(response => setCities(response))
      .catch(err => setErrs(err));

    // RESERVE TIME FETCHING
    getData(`${API_URL}working_hours?lang=${language}`)
      .then(response => setReserveTime(response))
      .catch(err => setErrs(err));
    // RESERVE TIME FETCHING
    getData(`${API_URL}types-reservation?lang=${language}`)
      .then(response => setReservationType(response))
      .catch(err => setErrs(err));
  }, []);
  // SUBMIT FORMIK
  async function submitRegister(values) {
    const datey = new Date(values.reservation_day);

    // Format to YYYY-MM-DD
    const formattedDate = datey.toISOString().split('T')[0];
    values.reservation_day = formattedDate;



    try {
      const response = await postData(`${API_URL}reservation`, values);
      setResponseMessage(response);
      if (response && response.data) {
        setSuccessed(true);
        setTimeout(() => {
        }, 2000);
      } else {
        setErrs(responseMessage.message)
        toast.error(responseMessage.message || language == 'ar' ? "حدث خطأ من فضلك اعد المحاوله في وقت لاحق" : "'Failed to reserve. Please try again.'");

      }
    } catch (err) {
      setErrs({ err });
      setResponseMessage(err);
      toast.error(err.message || language == 'ar' ? "حدث خطأ" : 'An error occurred'); // Show an error message
    }
  }
  // FORM VALIDATION
  const validationSchema = yup.object({
    first_name: yup.string()
      .max(25, `${language === 'ar' ? "اقصي حد للحروف 25" : "Max is 25 characters"}`)
      .min(3, `${language === 'ar' ? "اقل حد مسموح للحروف 3" : "Min is 3 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    last_name: yup.string()
      .max(25, `${language === 'ar' ? "اقصي حد للحروف 25" : "Max is 25 characters"}`)
      .min(3, `${language === 'ar' ? "اقل حد مسموح للحروف 3" : "Min is 3 characters"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    age: yup.number()
      .typeError(`${language === 'ar' ? "يجب أن يكون الرقم" : "Must be a number"}`)
      .max(150, `${language === 'ar' ? "اقصي عمر 150  سنه" : "Max is 150 years"}`)
      .min(3, `${language === 'ar' ? "اقل عمر 3 سنوات" : "Min is 3 years"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    mobile: yup.string()
      .min(11, `${language === 'ar' ? "ادخل رقم صحيح اقل عدد 11 رقم" : "Enter valid phone, Min 11 numbers"}`)
      .max(20, `${language === 'ar' ? "ادخل رقم صحيح اكثر عدد 20 رقم" : "Enter valid phone, Max 20 numbers"}`)
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),


    reservation_type_id: yup
      .string()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    city_id: yup
      .string()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),

    marital_status: yup.string()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`),
    reservation_day: yup.date()
      .required(`${language === 'ar' ? "الحقل مطلوب" : "Field is required"}`)
      .nullable()
  });

  // FORMIK OBJECT
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      age: '',
      city_id: '',
      marital_status: '',
      reservation_type_id: '',
      reservation_day: '', // Holds the selected reservation time
      mobile: "",
    },
    validationSchema,
    onSubmit: submitRegister,
  });
  const arabicDaysToNumbers = (day) => {
    const dayMapping = {
      "الأحد": 0,      // Sunday
      "الاثنين": 1,    // Monday
      "الثلاثاء": 2,   // Tuesday
      "الاربعاء": 3,   // Wednesday
      "الخميس": 4,     // Thursday
      "الجمعة": 5,     // Friday
      "saturday": 6,     // Saturday
      "sunday": 0,      // Sunday
      "monday": 1,    // Monday
      "tuesday": 2,   // Tuesday
      "wednesday": 3,   // Wednesday
      "thursday": 4,     // Thursday
      "friday": 5,     // Friday
      "saturday": 6       // Saturday
    };

    // Return the corresponding number for the given day, or -1 if the day is invalid
    return dayMapping[day] !== undefined ? dayMapping[day] : -1;
  };

  if (errors) {
    return (
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="alert alert-danger">
          Error loading data: {errors.message}
        </div>
      </div>
    );
  }

  return (
    <section className='signup my-5 p-3 '>

      {/* SUCCESSED POPOVER */}
      {successed ? (
        <div
          className="success-popover w-100 h-100 position-fixed top-0 start-0 z-3 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} // خلفية شفافة
        >
          <div
            className="success-card d-flex justify-content-center align-items-center flex-column bg-white p-5 rounded-5 shadow-lg"
            style={{
              animation: "fadeIn 0.5s ease-in-out",
              maxWidth: "500px", // تحديد حجم النافذة
              textAlign: "center", // محاذاة النص في المنتصف
            }}
          >
            <i
              className="fa-solid fa-check-circle mb-3"
              style={{ fontSize: "50px", color: "green" }}
            ></i> {/* أيقونة ناجحة */}
            <h3
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              {language == "ar"
                ? "لقد تم طلب الحجز بنجاح!"
                : "Reservation Successful!"}
            </h3>
            <p style={{ fontSize: "1rem", marginBottom: "20px" }}>
              {language == "ar"
                ? "سيتم إرسال رسالة بتأكيد الموعد على الواتساب."
                : "A confirmation message will be sent to your WhatsApp."}
            </p>
            <button
              onClick={() => setSuccessed(false)}
              className="btn btn-success px-4 py-2"
              style={{ fontSize: "1rem" }}
            >
              {language == "ar" ? "غلق" : "Close"}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {/*  */}
      <form className={` reserve row g-2 bg-white shadow p-3 ${language == 'ar' ? "home-input-translate" : "ltr"}`} onSubmit={formik.handleSubmit}>
        {responseMessage && responseMessage.data && <div className="text-danger">{responseMessage.data.email}</div>}

        {/* FNAME */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-regular fa-user"></i> {`${language == "ar" ? "الاسم الأول" : "First name"}`}
          </label>
          <input
            placeholder={`${language == "ar" ? "مثال محمد" : "ex:Mohammed"}`}
            type="text"
            id='first_name'
            name='first_name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            className={`${formik.errors.first_name && formik.touched.first_name ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          />
          {formik.errors.first_name && formik.touched.first_name && (
            <div className="text-danger">{formik.errors.first_name}</div>
          )}
        </div>
        {/* LNAME */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-solid fa-user"></i> {`${language == "ar" ? "الاسم الأخير" : "Last name"}`}
          </label>
          <input
            placeholder={`${language == "ar" ? "مثال محمد" : "ex:Mohammed"}`}
            type="text"
            id='last_name'
            name='last_name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            className={`${formik.errors.last_name && formik.touched.last_name ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          />
          {formik.errors.last_name && formik.touched.last_name && (
            <div className="text-danger">{formik.errors.last_name}</div>
          )}
        </div>
        {/* MOBILE */}
        {/* Country Code Input */}
        <div className="col-md-3 d-flex ">
          <div className="col-5" >
            <label className='p-2'> Code</label>
            <PhoneInput
              international
              value={countryCode}

              defaultCountry="SA" // Default country for the phone input
              onChange={(code) => {
                setCountryCode(code); // Update country code state
                handlePhoneChange(); // Update Formik's `mobile` value
              }}
              className="form-control  "
              placeholder="Country Code"
            />
          </div>
          {/* Phone Number Input */}
          <div className="">
            <label className='text-dark p-2' htmlFor="email">{language == 'ar' ? "رقم الهاتف" : "Phone number"}</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value); // Update phone number state
                handlePhoneChange(); // Update Formik's `mobile` value
              }}
              onBlur={formik.handleBlur}
              className="form-control "
              placeholder="1234567890"
            />
               {formik.errors.mobile && formik.touched.mobile && (
            <div className="text-danger">{formik.errors.mobile}</div>
          )}
          </div>
       
        </div>
     
        {/* AGE */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-solid fa-calendar-alt"></i> {language == 'ar' ? "العمر" : "Age"}
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="26"
            type="text"
            id='age'
            name='age'
            value={formik.values.age}
            className={`${formik.errors.age && formik.touched.age ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          />
          {formik.errors.age && formik.touched.age && (
            <div className="text-danger">{formik.errors.age}</div>
          )}
        </div>
        {/* CITY */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-solid fa-city"></i> {`${language == "ar" ? "المدينه" : "City"}`}
          </label>
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id='city_id'
            name='city_id'
            value={formik.values.city_id}
            className={`${formik.errors.city_id && formik.touched.city_id ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          >
            <option value="">{`${language == "ar" ? " اختر المدينه" : "Choose the city"}`}</option>
            {cities.map((city, ind) => <option value={city.id} key={ind}>{city?.name}</option>)}
            {getLoading && <option>loading</option>}
          </select>
          {formik.errors.city_id && formik.touched.city_id && (
            <div className="text-danger">{formik.errors.city_id}</div>
          )}
        </div>
        {/* MARITAL STATUS */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-solid fa-heart"></i> {`${language == "ar" ? "الحالة الاجتماعية" : "Marital status"}`}
          </label>
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id='marital_status'
            name='marital_status'
            value={formik.values.marital_status}
            className={`${formik.errors.marital_status && formik.touched.marital_status ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          >
            <option value="">{`${language == "ar" ? "اختر الحاله" : "Choose status"}`}</option>
            <option value="single">{`${language == "ar" ? "اعزب" : "Single"}`}</option>
            <option value="married">{`${language == "ar" ? "متزوج" : "Married"}`}</option>
          </select>
          {formik.errors.marital_status && formik.touched.marital_status && (
            <div className="text-danger">{formik.errors.marital_status}</div>
          )}
        </div>
        {/* RESERVATION TYPE */}
        <div className="col-md-3">
          <label className="label p-2">
            <i className="fa-solid fa-book"></i> {`${language == "ar" ? "نوع الحجز" : "Reservation type"}`}
          </label>
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder=""
            id='reservation_type_id'
            name='reservation_type_id'
            value={formik.values.reservation_type_id}
            className={`${formik.errors.reservation_type_id && formik.touched.reservation_type_id ? 'py-1 input-error' : 'py-1'} ${language === 'ar' ? '' : 'ltr'}`}
          >
            <option value="">{`${language == "ar" ? "اختر نوع الحجز" : "Choose reservation type"}`}</option>
            {reservationType.map((type, ind) => <option value={type.id} key={ind}>{type?.name}</option>)}
            {getLoading && <option>loading</option>}
          </select>
          {formik.errors.reservation_type_id && formik.touched.reservation_type_id && (
            <div className="text-danger">{formik.errors.reservation_type_id}</div>
          )}

        </div>

        {/* Reservation Day Date Picker */}
        <div className="col-md-3">
          <label className="text-dark m-0"></label>
          <label className="label p-2">
            <i className="fa-solid fa-clock"></i> {language == "ar" ? "اختر تاريخ" : "Select a Date"}
          </label>
          <DatePicker
            selected={formik.values.reservation_day}
            onChange={(date) => formik.setFieldValue('reservation_day', date)}
            filterDate={isDateAllowed}
            value={formik.values.reservation_day}
            placeholderText="Select a valid date"
            dateFormat="MMMM d, yyyy"
            className="form-control py-1"
            id='reservation_day'
            name='reservation_day'
          />
          {formik.errors.reservation_day && formik.touched.reservation_day && (
            <div className="text-danger">{formik.errors.reservation_day}</div>
          )}
        </div>
        <div className="col-md-3 d-flex align-items-end">
          <button type="submit" className="btn border-0 bg-mainColor text-white w-100" disabled={loading}>
            {loading ? <i className="fa-solid fa-spinner fa-spin"></i> : `${language == "ar" ? "احجز الان" : "Reserve now"}`}
          </button>
        </div>
      </form>
      <ReservationCard />
      <ToastContainer />
    </section>
  );
}
