import React, { useEffect, useState } from 'react'
import ServicesCard from './ServiceCard/ServicesCard'
import useGetData from '../APIs/useGetData'
import { API_URL } from '../../config'
import Loader from '../Loader/Loader'
import { useLanguage } from '../Contexts/LanguageContext'
export default function ServicesBody({}) {
    const [response, setResponse] = useState([])
    const [errs, setErrs] = useState(null)
const {language}=useLanguage()

    const { getData, getLoading } = useGetData()
    useEffect(() => {
        getData(`${API_URL}services?lang=${language}`)
            .then(res => setResponse(res.items))
            .catch(err => setErrs({ err }))
    }, [language])
    if(errs) return <div className='alert text-danger text-center'>{`${language=='ar'?"حدث خطأ ما من فضلك اعد التحميل":"some thing wrong happened ,please reload"}`}</div>
    return (<>
        {getLoading ?<Loader/>:
            <section className="row g-3 p-0 my-md-5 my-3">
                {response?.map((service, ind) => (
                    <div key={ind} className="col-lg-3 col-md-6 p-0">
                        <ServicesCard data={service} />
                    </div>
                ))}
            </section>
            }
    </>
    )
}
