import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import useGetDataAuth from '../APIs/useGetDataAuth';
import { API_URL } from '../../config';

export default function ProtectedRoute({ children }) {
  const { token, setToken } = useContext(UserContext);
  const { getDataAuth, loading } = useGetDataAuth();
  const [err, setErr] = useState(null);
  const navigate = useNavigate()
  // Retrieve token from localStorage if not present in context
  const storedToken = token || localStorage.getItem('token');

  useEffect(() => {
    if (storedToken) {
      // Attempt to fetch authenticated user data
      getDataAuth(`${API_URL}profile`, storedToken)
        .then((res) => {
          if (res.response?.status === 401) {
            setErr('401'); // Not authorized
          }
        })
        .catch((err) => {
          // Catch other errors, e.g., network issues, or 401 errors
          if (err.response?.status === 401) {
            setErr('401'); // Not authorized
          } else {
            setErr('Error'); // Handle other errors if needed
          }
        });
    } else {
      // No token, mark as unauthorized and stop loading
      setErr('401');
    }
  }, []);


  // If there is no valid token or the user is unauthorized (401), redirect to login
  if (!storedToken || storedToken === 'null' || err === '401') {
    // console.log(storedToken)
    localStorage.removeItem('token');
    setToken(null)
    navigate('/login')
    //  <Navigate to="/login"  />;
  }

  // If authenticated, render the protected route's children
  return children;
}
