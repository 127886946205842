import React, { useEffect, useState } from 'react'
import './About.modul.css'
import dentist from '../../assets/images/dentist.png'
import TextSlider from '../Slider/Slider'
import { useLanguage } from '../Contexts/LanguageContext'
import useGetData from '../APIs/useGetData';
import { API_URL } from '../../config'
import Loader from "../Loader/Loader"
import advenage1 from '../../assets/images/adve1.svg'
import clinic from '../../assets/images/clinik.png'
import BackText from '../../BackText/BackText'

export default function About() {
  const { language } = useLanguage()
  const { getData, getLoading, getErrors } = useGetData()
  const [data, setData] = useState([])  // Initialize as null to handle empty state
  const [adventage, setAdventage] = useState([])  // Initialize as null to handle empty state
  const [errs, setErrs] = useState(null)

  useEffect(() => {
    getData(`${API_URL}page/3?lang=${language}`)
      .then(res => {
        setData(res);
      })
    getData(`${API_URL}advantage?lang=${language}`)
      .then(res => {
        setAdventage(res);
      })
      .catch(err => setErrs(err));
  }, [language]);
  // HANDLE ERRORS
  if (getLoading) return <Loader />;
  if (getErrors || errs) return (
    <div className='alert alert-danger contain text-center my-5'>
      {getErrors?.message || errs?.message || "Something went wrong"}
    </div>
  );

  if (!data || !data.sections || data.sections.length === 0) {
    return (
      <div className='alert text-center'>
        "Something happened, please reload"
      </div>
    );
  }

  return (<>
    <section id="about" className="about  mt-3 mb-5 py-md-5 my-md-5">
      {getLoading ? <Loader /> :
        <div className="contain  p-3 pb-3">
          <div className="row ">
            <div className="col-lg-5 d-flex flex-column justify-content-center my-3">
              <div className="position-relative">
                <BackText textAr={'من نحن'} textEn={"About us"} />
                <span>{`${data?.sections[0].sub_content} `}</span>
              </div>
              <h2 className=" under-line">{`${data?.sections[0].title}`}</h2>
              <p>
                {`${data?.sections[0].content}`}
              </p>

              <div className="row g-4">
                <ul className="d-flex flex-wrap row g-3">
                  <li className="whyus d-flex  col-md-6">
                    <h4 className="mb-0">{`${language == 'ar' ? "أجهزة طب الأسنان المتطورة" : "State-of-the-art dental equipment"}`}</h4>
                  </li>
                  <li className="whyus d-flex  col-md-6">
                    <h4 className="mb-0">{`${language == 'ar' ? "متخصصون ماهرون ومؤهلون" : "Skilled and qualified professionals"}`}</h4>
                  </li>
                  <li className="whyus d-flex  col-md-6">
                    <h4 className="mb-0">{`${language == 'ar' ? "مجموعة كاملة من خدمات طب الأسنان" : "Full range of dental care"}`}</h4>
                  </li>
                  <li className="whyus d-flex  col-md-6">
                    <h4 className="mb-0">{`${language == 'ar' ? "أجواء مريحة" : "relaxing atmosphere"}`}</h4>
                  </li>
                </ul>
                <div className="about-btns ">
                  <a href='/reserve' className="btn bg-mainColorLight text-mainColor px-4 p-2 mx-2">{language=='ar'?"احجز الان":"Book now"}</a>
                  <a href='/contact' className="btn bg-mainColor text-white px-4 p-2 mx-2">{language=='ar'?"اتصل بنا":"Contact us"}</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 d-flex flex-column justify-content-center my-md-5  ">
              <div className="row   ">
                <div className=" col-md-8 m-auto rounded-5"  >
                  <img src={data?.sections[0].images || dentist} className='w-100 about-fImage rounded-5' alt="dentist" />
                </div>
                {/* ABOUT STATISTICS */}
                <div className="col-lg-4 col-md-6 about-statistic text-center p-2 ">
                  <div>
                    <h2 className="num">
                      42K
                    </h2>
                    <h6>حاله تم شفائها</h6>
                  </div>
                  <div>
                    <h2 className="num">
                      42K
                    </h2>
                    <h6>حاله تم شفائها</h6>
                  </div>
                  <div>
                    <h2 className="num">
                      42K
                    </h2>
                    <h6>حاله تم شفائها</h6>
                  </div>
                  <div>
                    <h2 className="num">
                      42K
                    </h2>
                    <h6>حاله تم شفائها</h6>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>}
    </section>
    {/* WHAT WE OFFER */}
    <section className='what-we-offer bg-mainColor py-md-5 my-md-5 '>
      {getLoading ? <Loader /> :
        <div className="row">
          <div className="col-lg-6">
            <img src={data?.sections[1].images || clinic} style={{ maxHeight: "600px", objectFit: 'cover' }} className='w-100' alt="clinic" />
          </div>
          <div className="col-lg-5 text-white d-flex flex-column justify-content-center  align-items-center text-start py-lg-5  p-3 ">
            <div className="w-100">
              <div className="position-relative">
                <BackText textAr={"لماذا نحن"} textEn={"Why us"} color={"red"}/>
                <span className='text-white fw-light text-start'>{data?.sections[1].sub_content}</span>
              </div>

              <h2 className=" under-line text-start">{data?.sections[1].title}</h2>
              <h4>
                {data?.sections[1].content}
              </h4>
            </div>

            <div className="row ">
              <ul className="d-flex flex-wrap row g-3">
                <li className="whyus d-flex align-items-center col-md-6">
                  <h4 className="mb-0">{`${language == 'ar' ? "أجهزة طب الأسنان المتطورة" : "State-of-the-art dental equipment"}`}</h4>
                </li>
                <li className="whyus d-flex align-items-center col-md-6">
                  <h4 className="mb-0">{`${language == 'ar' ? "متخصصون ماهرون ومؤهلون" : "Skilled and qualified professionals"}`}</h4>
                </li>
                <li className="whyus d-flex align-items-center col-md-6">
                  <h4 className="mb-0">{`${language == 'ar' ? "مجموعة كاملة من خدمات طب الأسنان" : "Full range of dental care"}`}</h4>
                </li>
                <li className="whyus d-flex align-items-center col-md-6">
                  <h4 className="mb-0">{`${language == 'ar' ? "أجواء مريحة" : "relaxing atmosphere"}`}</h4>
                </li>
              </ul>

            </div>
          </div>
        </div>}
    </section>
    {/* OUR SERVICES */}
    <section className='our-services my-md-5'>
      {getLoading ? "" : ""}
      <div className="contain   py-md-5 p-3">
        <div className="row d-flex justify-content-between align-items-center" >

          <div className="col-md-5 ">
            <div className="position-relative">
              <BackText textAr={"لماذانحن"} textEr={"Why us"} />

              <span>  {language == "ar" ? "خدماتنا المميزه" : "Our specially services"}</span>
              <h2>{language == 'ar' ? "لماذا نحن أفضل خيارات أسنانك للتميز الذي لا يضاهى" : "Why We Are Your Best Dental Choice for Unparalleled Excellence"}</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 medical-adventage">
            <h5>{language == "ar" ? "خدمات طبية متحصصة تلبي إحتياجاتك، أفضل عيادات لطب الأسنان في المملكة السعودية. نحن نقدم جميع العلاجات عن طريق الفم " :
              "Specialized medical services that meet your needs, the best dental clinics in Saudi Arabia. We provide all oral treatments"}</h5>
          </div>
        </div>
        {getLoading ? <Loader /> :

          <div className="adventage my-md-5">
            <div className="row g-5 d-flex justify-content-between">

              {adventage.map((adv, ind) => {
                return (
                  <div className="col-lg-4 col-md-6 px-5" key={ind}>
                    <div className="top d-flex px-2 d-flex justify-content-between">
                      <img src={adv.image || advenage1} style={{ maxWidth: "500px" }} className='' alt="adventage" />
                      <h2>0{ind + 1}</h2>
                    </div>

                    <div className="bot">
                      <h3>{adv.name}</h3>
                      <p>{adv.content}</p>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>


        }
      </div>

    </section>
    <section className='slider'>
      <TextSlider />

    </section>


  </>
  )
}
