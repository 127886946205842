import React from 'react';

export default function ReservationCard({ showCard, onClose }) {
  if (!showCard) return null;

  return (
    <div 
      className="position-fixed top-50 start-50 translate-middle bg-white shadow p-5 rounded"
      style={{ zIndex: 1050, width: '50%', textAlign: 'center' }}
    >
      <p className="fs-4">
        لقد تم الحجز يرجي مراجعة الواتس
      </p>
      <button onClick={onClose} className="btn btn-danger mt-3">
        إغلاق
      </button>
    </div>
  );
}
