// App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Component/Layout/layout";
import Home from "./Component/Home/Home";
import Article from "./Component/Article/Article";
import Contact from "./Component/Contact/Contact";
import About from "./Component/About/About";
import Services from "./Component/Services/Services";
import Team from "./Component/Team/Team";
import ServiceDetails from "./Component/Services/ServiceDetails/ServiceDetails";
// import ArticlePostDetails from "./Component/ArticlePostDetails/ArticlePostDetails";
import Login from "./Component/Login/Login";
import SignUp from "./Component/SignUp/SignUp";
import Categories from "./Component/Categories/Categories";
import CategoryPosts from "./Component/Categories/CategoryPosts";
import Profile from "./Component/Profile/Profile";
import Xrays from "./Component/Xrays/Xrays";
import Reserve from "./Component/Reserve/Reserve";
import NotFound from "./Component/NotFound/NotFound";
import ConfirmReservation from "./Component/Reserve/ConfirmReservation/ConfirmReservation";
import ForgetPassword from "./Component/ForgetPassword/ForgetPassword";
import ResetPassword from "./Component/ForgetPassword/ResetPassword";
import PostDetails from "./Component/Article/PostDetails/PostDetails";
import EditProfile from "./Component/Profile/EditProfile/EditProfile";
import ReservationDetails from "./Component/Reserve/ReservationDetails/ReservationDetails";
import { LanguageProvider } from "./Component/Contexts/LanguageContext"; // Import the LanguageProvider
import ProtectedRoute from "./Component/ProtectedRoute/ProtectedRoute";
import ProtectIfAuthed from "./Component/ProtectedRoute/ProtecetIfAuthed";
// import jwtDecode from "jwt-decode";

export default function App() {

  // const token = localStorage.getItem("token");

  // try {
  //   const decodedToken = jwtDecode(token);

  //   // Check for necessary claims (e.g., expiration)
  //   if (decodedToken.exp * 1000 < Date.now()) {
  //     // Token is expired
  //     console.log("Token has expired");
  //   } else {
  //     // Token is valid
  //     console.log("Token is valid");
  //   }
  // } catch (error) {
  //   // Token is invalid or not a JWT
  //   console.error("Invalid token");
  // }
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="articles" element={<Article />} />
            <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            {/* <Route path="articlepostdetails" element={<ArticlePostDetails />} /> */}
            <Route path="servicedetails/:id" element={<ServiceDetails />} />
            <Route path="categories" element={<Categories />} />
            <Route path="categoryposts/:id" element={<CategoryPosts />} />
            <Route
              path="forgetpassword"
              element={
                <ProtectIfAuthed>
                  <ForgetPassword />
                </ProtectIfAuthed>
              }
            />
            <Route
              path="login"
              element={
                <ProtectIfAuthed>
                  {" "}
                  <Login />
                </ProtectIfAuthed>
              }
            />
            <Route path="reserve" element={<Reserve />} />
            <Route
              path="confirm-reservation/:id"
              element={<ConfirmReservation />}
            />
            <Route
              path="signup"
              element={
                <ProtectIfAuthed>
                  <SignUp />
                </ProtectIfAuthed>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="postdetails/:postId" element={<PostDetails />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="xrays" element={<Xrays />} />
            <Route
              path="editprofile"
              element={
                <ProtectedRoute>
                  <EditProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="reservationdetails/:id"
              element={<ReservationDetails />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}
