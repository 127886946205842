import React, { useEffect, useState } from "react";

import "./Contact.modul.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGetData from '../APIs/useGetData'
import ContactForm from "./ContactForm";
import { useLanguage } from "../Contexts/LanguageContext";
import { API_URL } from "../../config";
export default function Contact() {
  const { language } = useLanguage()
  const [workingHours, setWorkingHours] = useState([])
  const [errs, setErrs] = useState([])
  const { getData } = useGetData()

  useEffect(() => {
    try {

      getData(`${API_URL}working_hours?lang=${language}`)
        .then(response => setWorkingHours(response))
        .catch(err => setErrs(err))


    } catch (err) {
      setErrs(err)
    }
  }, [workingHours])
  return (
    <section>
      <div className="contain my-lg-5 my-2 p-3">
        {/* HEAD */}
        <div className="contact-head col-lg-6">
          <h3>{language == 'ar' ? "احجز موعد" : "Book an appointment"}</h3>
          <h1 className="my-md-4 font-md-h2">
            {language == 'ar' ? "نحن هنا لخدمتك وللإهتمام بصحة أسنانك" : "We are here to serve you and take care of your dental health."}
          </h1>
          <p>
            {language == 'ar' ?
              "نحن ملتزمون بتقديم رعاية أسنان استثنائية لمرضانا. صحتك الفموية هي أولويتنا، ونسعى جاهدين لخلق بيئة مريحة وترحيبية للجميع. إذا كان لديك أي أسئلة أو ترغب في تحديد موعد، فلا تتردد في التواصل معنا. نحن هنا للمساعدة!" :
              " We are dedicated to providing exceptional dental care to our patients. Your oral health is our priority, and we strive to create a comfortable and welcoming environment for everyone. If you have any questions or would like to schedule an appointment, please feel free to reach out to us. We're here to help!"}
          </p>
        </div>

        {/* Form Section */}
        <div className="contact-page">
          <div className="contact-container">
            {/* Right Side Form */}
            <ContactForm />
            {/* Left Side Information */}
            <div className="contact-info  m-lg-5  mt-md-4">
              {/* Info Boxes (repeat as needed) */}
              <div className="info-box">
                <div className="div-center text-start">
                  <div className="row g-1 align-items-center ">
                    <div className="col-auto">
                      <div className="icon">
                        <i className="fas fa-location-dot"></i>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center flex-column">
                      <h6>{language === "ar" ? "مواعيد العمل" : "Working hours"}</h6>
                      <ul className="d-flex  list-unstyled align-items-center mb-0">
                        {workingHours?.map((hour, ind) => {
                          return (
                            <li key={ind} className="mx-1">
                              {hour.working_days}
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className="info-box">
                <div className="div-center text-start">
                  <div className="row g-1 align-items-center ">
                    <div className="col-auto">
                      <div className="icon">
                        <i className="fas fa-clock"></i>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-item-center flex-column">
                      <h6>{language == "ar" ? "البريد الإلكتروني" : "Email"}</h6>
                      <h6>
                        Smile01@gmail.com
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-box">
                <div className="div-center text-start">
                  <div className="row g-1 align-items-center ">
                    <div className="col-auto">
                      <div className="icon">
                        <i className="fas fa-message"></i>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-item-center flex-column">
                      <h6>{language == "ar" ? "البريد الإلكتروني" : "Email"}</h6>
                      <h6>
                        Smile01@gmail.com
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-box">
                <div className="div-center text-start">
                  <div className="row g-1 align-items-center ">
                    <div className="col-auto">
                      <div className="icon">
                        <i className="fas fa-phone"></i>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-item-center flex-column">
                      <h6>{language == 'ar' ? "رقم الهاتف" : "Phone number"}</h6>
                      <h6>
                        0900-78601
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* Repeat Info Boxes as necessary */}
            </div>
          </div>
        </div>


      </div>
    </section>
  );
}
