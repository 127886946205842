// Card.js
import React from 'react';
import './ServicesCard.modul.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Contexts/LanguageContext';

const Card = ({ data }) => {
  const {language}=useLanguage()
  return (
    <>
      {data ? <div className="service-card h-100 ">
        <div className="d-flex justify-content-center align-items-center  ">
          <div className="icon mx-1 ">
            <i className='fas fa-tooth'></i>
          </div>
          <h3> {data.name || "طب الأسنان العام"}</h3>
        </div>
        <div className="">
          <p>{data.content || "دور طبيب الأسنان العام مهم جدا لضمان رعاية الأسنان والفم آمنة وفعالة"}</p>
        </div>
        <Link to={`/servicedetails/${data.id}`} className="button"> {language=="ar"?"اعرف هذا أكثر":"Show more"}</Link>
      </div>
        : "No Services"}

    </>

  );
};

export default Card;
