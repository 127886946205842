import React, { useContext, useEffect, useState } from 'react';
import useGetDataAuth from '../../APIs/useGetDataAuth';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../config';
import { useLanguage } from '../../Contexts/LanguageContext';

// import Loader from '../../Loader/Loader'
export default function AlluserReservations() {
    const { getDataAuth, loading, errors } = useGetDataAuth();
    const { token } = useContext(UserContext);
    const storedToken = token || localStorage.getItem('token');
    const { language } = useLanguage()
    const [reservations, setReservations] = useState([]);
    const [errs, setErrs] = useState([]);
    const navigate = useNavigate();

    const handleRowClick = (reservationId) => {
        navigate(`/reservationdetails/${reservationId}`);
    };

    useEffect(() => {
        try {
            if (storedToken) {
                getDataAuth(`${API_URL}reservationsUser`, storedToken)
                    .then((res) => {
                        setReservations(res); // Assuming res is an array of reservations
                    })
                    .catch((err) => setErrs({ err }));
            }
        } catch (err) {
            setErrs({ err });
        }
    }, [storedToken]);
    // if (errs) return <div className="alert alert-danger text-center">{errs.message || "Some thing wrong happened"}</div>

    return (
        <section className=''>
            {errors ? <div className='alert text-warning '>{errors?.response?.data?.message || "some thing wrong happened"}</div> :
                (loading ? <Loader /> :
                    <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{language == 'ar' ? ":يوم الحجز" : "Reserve day:"}</th>
                                <th scope="col">{language == 'ar' ? ":وقت الحجز" : "Reserve Time:"}</th>
                                <th scope="col">{language == 'ar' ? ":نوع الحجز" : "Reserve Type:"}</th>
                                <th scope="col">{language == 'ar' ? ":الحاله" : "State:"}</th>
                            </tr>
                        </thead>
                        <tbody className='bg-warning'>

                            {reservations.map((reservation, index) => (

                                <tr key={reservation.reservation_id} className='reserve-pointer' onClick={() => handleRowClick(reservation.reservation_id)} >
                                    <th scope="row">{index + 1}</th>
                                    <td>{new Date(reservation.reservation_day).toLocaleDateString()}</td>
                                    <td>{reservation.reservation_time || 'لايوجد'}</td>
                                    <td>{reservation.reservation_type_id}</td>
                                    <td>{reservation.status_reservation}</td>
                                </tr>
                            ))
                            }

                        </tbody>
                    </table>
                )
            }
        </section>
    );
}
