import { useState } from 'react';
import axios from 'axios';

export default function useGetDataAuth() {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);

    const getDataAuth = async (url,token) => {
        setLoading(true);
        setErrors(null);

        try {
            const response = await axios.get(url,{
                headers:{
                'Authorization': `Bearer ${token}`,
                }
            });
            return response.data.data;
        } catch (err) {
            setErrors(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { getDataAuth, loading, errors };
}
