import React from 'react';
import './DoctorCard.modul.css';
import Button from '../Button/Button';
import SocialLinks from '../SocialIcons/SocialIcons';
import {useLanguage} from "../Contexts/LanguageContext"
export default function DoctorCard({ doctorImg, name, job }) {
  const {language}=useLanguage()
  return (
    <div className='doctorCard'>
      <img src={doctorImg} className="w-100  " alt="Doctor" />
      <h3>{name}</h3>
      <h4>{job}</h4>
      <div className="social-ic">
        <SocialLinks/>
      </div>
    </div>
  );
}
