import React from 'react'
import Slider from 'react-slick'
import doctorImg from '../../../assets/images/dentist.png'
import { useLanguage } from '../../Contexts/LanguageContext'

export default function HomeMoreVideosSlider() {
    const settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024, // You can adjust this breakpoint
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768, // Adjust for tablets
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 576, // Adjust for mobile
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      };
     const {language}= useLanguage()
  return (
    <section className="home-videos-sec home-bottom-slider my-md-5 my-3 py-md-5   ">
    <div className="row m-0  w-100 align-items-center ">
      <div className="col-lg-4  me-auto">
        <h3 className="underline">{language=="ar"?"من داخل عيادات العاجي":"From inside Al-Aji clinics"} </h3>
        <h2 className="py-3 font-md-h2">{language=='ar'?'خدماتنا المتميزة مصدر سعادة لكافة المرضى .':'Our distinguished services are a source of happiness for all patients.'}</h2>
       
          <p>
          {language=="ar"?"  نقوم بتوثيق العديد من عمليات التدريب للسماح لمرضانا برؤية أكثر وضوحاً كيف نعمل حتى لا تترددوا في تجربة خدماتنا":
          "The best dental clinics in the Kingdom of Saudi Arabia. We provide all oral treatments including dental implants."}
           
          </p>
      </div>
      <div className="col-lg-6   ">
        <Slider {...settings2} className='w-100 '>
      
          <div className="slide w-100 h-100  bg-white ">
            <div className="opinion d-flex flex-column justify-content-center align-items-center text-center  m-auto ">
              <div className='doctorCard '>
                <img src={doctorImg} className="w-100 h-100" style={{height:"00px"}} alt="Doctor" />
           
                
              </div>

            </div>
          </div>
      
          <div className="slide">
            <div className="opinion d-flex flex-column justify-content-center align-items-center text-center  m-auto ">
              <div className='doctorCard '>
                <img src={doctorImg} className="w-100 h-100" style={{height:"300px"}} alt="Doctor" />
           
                
              </div>

            </div>
          </div>
      
          <div className="slide">
            <div className="opinion d-flex flex-column justify-content-center align-items-center text-center  m-auto ">
              <div className='doctorCard  '>
                <img src={doctorImg} className="w-100 h-100" style={{height:"500px"}} alt="Doctor" />
           
                
              </div>

            </div>
          </div>
        
        </Slider>
      </div>
    </div>
  </section>
  )
}
