import React from 'react'
import './Loader.modul.css'
export default function Loader() {
    return (
        <div className="loader-div  d-flex justify-content-center align-items-center  flex-column  " >
            <div className="loader"></div>
            <h4>LOADING</h4>
        </div>
    )
}
