import React, { useEffect, useState } from 'react';
import './Statistic.modul.css';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useLanguage } from '../Contexts/LanguageContext';
import useGetData from "../APIs/useGetData";
import { API_URL } from "../../config";
import Loader from '../Loader/Loader';

export default function Statistic() {
  const { language } = useLanguage();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [data, setData] = useState([]);
  const [errs, setErrs] = useState(null);
  const { getData, getLoading } = useGetData();

  useEffect(() => {
    getData(`${API_URL}overview`)
      .then(res => setData(res))
      .catch(err => setErrs(err));

  }, [language]); // Add language to the dependency array to re-fetch on language change

  if (getLoading) {
    return <Loader />;
  }

  if (errs) {
    return <div></div>;
  }

 
  return data.length > 0?
 (
    <section className={`statistic ${language === 'en' ? "text-end" : ""}`}>
      <div className="contain m-auto stats-section py-5">
        <div className="row text-center justify-content-center" ref={ref}>
          {data?.map((count, ind) => (
            <div className="col-lg-3 col-md-6 mb-4" key={ind}>
              <div className="stat-item">
                <div className="icon-container me-3">
                  {count.image ? <img src={count.image} alt={count.name}  style={{width:"50px",height:"50px",objectFit:"cover"}}/> : <i className="fas fa-calendar-alt"></i>}
                </div>
                <div>
                  {inView && (
                    <h3 className={`mt-2 mb-0 ${language === 'ar' ? "rtl" : "text-end"}`}>
                      <span className="plus">+</span>
                      <CountUp start={0} end={count.number || 345} duration={2.5} />
                    </h3>
                  )}
                  <p>{count.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : '';
}
