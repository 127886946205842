import React, { useEffect, useState } from "react";
import ArticlePost from "./ArticlePost";
import Loader from "../Loader/Loader";
import z from "../../assets/images/z.png";
import "./ArticlePost.modul.css";
import useGetData from "../APIs/useGetData";
import { API_URL } from '../../config';
import { useLanguage } from "../Contexts/LanguageContext";

export default function Article() {
  let [articleData, setArticleData] = useState([]);
  let [errs, setErrs] = useState(null);
  let [highCommentsData, setHighCommentsData] = useState([]);
  const { language } = useLanguage(); // Use the useLanguage hook to access language

  const { getData, getLoading, errors } = useGetData();
  useEffect(() => {
    getData(`${API_URL}posts?lang=${language}`)
      .then(data => setArticleData(data))
      .catch(err => setErrs(err));

    getData(`${API_URL}highComments?lang=${language}`)
      .then(data => setHighCommentsData(data))
      .catch(err => err);

  }, [language]);


  if (errors || errs) {
    return <div className="w-100 vh-100 d-flex justify-content-center align-items-center"><div className="alert alert-danger">No posts founded</div></div>;
  }

  return (<>
    {
      !getLoading ? <section className="article mt-md-5 my-2 px-3">
        <div className="contain">
          {/* First Section */}
          <h1 className="my-5">{language == 'ar' ? "أحدث المدونات" : "Latest articles"}</h1>
          <div className="row">
            <div className="col-lg-6 px-3 pb-0">
              {articleData?.length > 0 && (
                <ArticlePost data={highCommentsData[0]} />
              )}
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                {highCommentsData?.slice(1,4).map((high, index) => (
                  <React.Fragment key={index}>
                    <div className="col-md-6">
                      <img src={high.cover_photo_path||z} className="w-100" alt="Article Image" style={{maxHeight:'150px',objectFit:'cover'}} />
                    </div>
                    <div className="col-md-6">
                      <h6>{high.title}</h6>
                      <p>{high.body}</p>
                    
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          {/* Posts Section */}
          <h1 className="my-5">{language == 'ar' ? "مقالات عاجي الرئيسيه" : "Aaji main articles"}</h1>
          <div className="row g-5">
            {articleData.map((post, ind) => (
              <div className="col-lg-4 col-md-6 mb-4" key={ind}>
                <ArticlePost data={post} />
              </div>
            ))}
          </div>

          {/* HighComments Sections */}
          <h1 className="my-5">{language == 'ar' ? "مقالات الاكثر قراءه" : "Most read articles"}</h1>
          <div className="row g-5">
            {highCommentsData.map((post, ind) => (
              <div className="col-md-4 mb-4" key={ind}>
                <ArticlePost data={post} />
              </div>
            ))}
          </div>
        </div>
      </section> : <Loader />
    }

  </>

  );
}
