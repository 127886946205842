import React from "react";
import './TopNav.modul.css'
import SocialLinks from "../SocialIcons/SocialIcons";
import { useLanguage } from "../Contexts/LanguageContext";
export default function TopNav() {
  const {language}=useLanguage()
  return (
    <section className="top-nav w-100 bg-mainColor text-white p-3">
      <div className="contain">
        <div className="d-flex justify-content-between align-items-center topnav-column top-nav-column">

          <div className="left-section d-flex align-items-center">
            <i className="fas fa-location-arrow mx-2"></i>
            <h6 className="mb-0">{language=="ar"?"الرياض-حي البديعة طريق المدينة المنورة، ظهرة البديعة، الرياض 12784":"Riyadh - Al-Badia District, Madinah Road, Dhahran Al-Badia, Riyadh 12784"}</h6>
          </div>

          <div className="center-section d-flex align-items-center ">
            <div className="social-ic d-flex">
              <SocialLinks />
            </div>
          </div>

          <div className="right-section d-flex align-items-center">
            <i className="fas fa-envelope mx-2"></i>
            <a href="mailto:EtihadTravel@gmail.com" className="text-white">inf@aaji.sa</a>
          </div>

        </div>
      </div>
    </section>


  );
}
