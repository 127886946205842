import { useState } from 'react';
import axios from 'axios';

export default function usePostData() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (url, data, token = null) => {
        setLoading(true);
        setError(null);

        try {
            const config = {
                headers: {}
            };

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await axios.post(url, data, config);
            return response.data;
        } catch (err) {
            setError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { postData, loading, error };
}
