import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Categories.modul.css"; 
import Loader from "../Loader/Loader";
import useGetData from "../APIs/useGetData";
import { API_URL } from '../../config';
import { useLanguage } from "../Contexts/LanguageContext";

export default function Categories() {
 let [categories,setCategories]=useState()
 let [errs,setErrs]=useState()
 let {getData,getLoading,getError}=useGetData()
 const { language } = useLanguage(); // Use the useLanguage hook to access language
 
  useEffect(() => {
    try{

      getData(`${API_URL}categories?lang=${language}`)
      .then((response)=>setCategories(response))
      .catch(err => console.error(err));
    }catch(err){
      setErrs(err)
    }
  }, []);

  if (getLoading) return <Loader/>;
  if (getError) return <p>Error loading categories: {getError.message}</p>;
  if (errs) return <p>Error loading categories: {errs?.message}</p>;

  return (
    <div className="categories my-5 p-5 contain" >
      {categories?categories?.map(category => (
        <Link to={`/categoryposts/${category.id}`} key={category.id} className="category">
          <div className="category-content">
            <h3>{category.name}</h3>
            {/* Add more category details here */}
          </div>
        </Link>
      )):<div className="w-100 p-5 m-5 text-center"><h2>"Some thing happened or network error please reload"</h2></div>  }
    </div>
  );
}
