import React from 'react';
import w from '../../../assets/images/Group.png';
import { useLanguage } from '../../Contexts/LanguageContext';
import BackText from '../../../BackText/BackText';

export default function WhoAreWe({ homeData }) {
    const { sections } = homeData || {};
    const section = sections ? sections[2] : {}; // Get the third section
    const { language } = useLanguage()
    return (
        <section className="who-are-we row contain my-md-5 py-md-5 d-flex justify-content-between">
            <div className="col-lg-5 d-flex flex-column justify-content-center my-3">
                <div className="position-relative">
                    <span>{section.sub_content || ""}</span>
                    <BackText textAr={'من نحن'} textEn={'About us'}/>
                <h2 className="under-line">{section.title || ""}</h2>
                </div>
                <p>
                    {section.content}
                </p>
                <div className="row g-4">
                    <ul className="d-flex flex-wrap row g-3">
                        <li className="whyus d-flex col-md-6">
                            <h4 className="mb-0">{`${language == 'ar' ? "أجهزة طب الأسنان المتطورة" : "State-of-the-art dental equipment"}`}</h4>
                        </li>
                        <li className="whyus d-flex col-md-6">
                            <h4 className="mb-0">{`${language == 'ar' ? "متخصصون ماهرون ومؤهلون" : "Skilled and qualified professionals"}`}</h4>
                        </li>
                        <li className="whyus d-flex col-md-6">
                            <h4 className="mb-0">{`${language == 'ar' ? "مجموعة كاملة من خدمات طب الأسنان" : "Full range of dental care"}`}</h4>
                        </li>
                        <li className="whyus d-flex col-md-6">
                            <h4 className="mb-0">{`${language == 'ar' ? "أجواء مريحة" : "relaxing atmosphere"}`}</h4>
                        </li>
                    </ul>
                    <div className="about-btns">
                        <a href="/reserve" className="btn bg-mainColorLight text-mainColor px-5 p-3 m-2">{language == "ar" ? "احجز الان" : "Book now"}</a>
                        <a href="contact" className="btn bg-mainColor text-white px-5 p-3 m-2">{language == "ar" ? "اتصل بنا" : "Contact us"}</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center my-3">
                <img src={section.images ?? w} alt="Who We Are" className="img-fluid m-auto w-100" style={{ maxWidth: "750px", maxHeight: "500px", objectFit: "cover" }} />
            </div>
        </section>
    );
}
